import { useRef } from "react";
import {
  Car,
  StatusInsuranceCover,
  StatusVehicleLicense,
} from "../../../models/car/types";
import { useTranslation } from "react-i18next";

export const usePowerStatus = (car: Car) => {
  const { t } = useTranslation(["car"]);

  const DOCUMENTS_STATUS_COLOR_POWER = useRef({
    0: "#FFAB03",
    1: "#373A3C",
    2: "#373A3C",
    3: "#EB5757",
    4: "#EB5757",
  });

  const DOCUMENTS_STATUS_POWER = useRef({
    0: t("index.status.not_uploaded"),
    1: t("index.status.approved"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const CAR_STATUS_POWER = useRef({
    0: t("index.status.incomplete"),
    1: t("index.status.approved"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const HAS_GPS_STATUS_POWER = useRef({
    0: t("index.status.gps.unknown"),
    1: t("index.status.gps.false"),
    2: t("index.status.gps.true"),
  });

  const HAS_GPS_STATUS_COLOR_POWER = useRef({
    0: "#FFAB03",
    1: "#EB5757",
    2: "#373A3C",
  });

  return {
    DOCUMENTS_STATUS_COLOR_POWER,
    DOCUMENTS_STATUS_POWER,
    CAR_STATUS_POWER,
    HAS_GPS_STATUS_POWER,
    HAS_GPS_STATUS_COLOR_POWER,
  };
};
