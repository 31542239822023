import { InputLabel, Modal, TextField } from "@mui/material";
import "./AddClient.scss";
import { AddClientForm, AddClientProps, addClientSchema } from "./form";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { addClientByHost, editClientByHost } from "../../../services/client";
import { useQueryClient } from "react-query";
import { DomainContext } from "../../../providers/domainProvider";

export const AddClient = ({ open, setOpen, client }: AddClientProps) => {
  const { t } = useTranslation(["client"]);
  const { hostComercialId } = useContext(DomainContext);
  const queryClient = useQueryClient();

  const fetchAddClient = async (values: AddClientForm) => {
    if (client && client.id) {
      const response = await editClientByHost({
        id: client.id,
        ...values,
        phone: "+" + values.phone,
      });

      if (!response || response.status != 200) return;
    } else {
      const response = await addClientByHost({
        ...values,
        phone: "+" + values.phone,
      });

      if (!response || response.status != 201) return;
    }

    setOpen(false);
    formikAddClient.resetForm();
    queryClient.resetQueries(["clients"]);
  };

  useEffect(() => {
    if (hostComercialId) {
      formikAddClient.setFieldValue("hostComercialId", hostComercialId);
    }
  }, [hostComercialId]);

  const formikAddClient = useFormik<AddClientForm>({
    initialValues: {
      name: "",
      surName: "",
      email: "",
      phone: "",
      hostComercialId: 0,
    },
    validationSchema: addClientSchema,
    onSubmit: fetchAddClient,
  });

  useEffect(() => {
    if (client) {
      formikAddClient.setValues(
        {
          name: client.name,
          surName: client.surName,
          email: client.email,
          phone: client.phone.replace(/^\+/, ""),
          hostComercialId: hostComercialId,
        },
        true
      );
    }
  }, [client]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="add-client-modal"
    >
      <div className="add-client-container">
        <div className="add-client-header">
          <h4 className="add-client-header__title">
            {client
              ? t("modals.add_client.edit_client")
              : t("modals.add_client.upload_client")}
          </h4>
          <div className="btn-close-container">
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
        </div>
        <div className="add-client-body">
          <p className="add-client-body__subtitle">
            {t("modals.add_client.require_fields")}
          </p>
          <div className="add-client-body__input-container">
            <InputLabel htmlFor="custom-text-field" required>
              {t("modals.add_client.name.label")}
            </InputLabel>
            <TextField
              name="name"
              type="text"
              value={formikAddClient.values.name}
              onChange={formikAddClient.handleChange}
              onBlur={formikAddClient.handleBlur}
              required
              className="add-client-body__input"
              placeholder={t("modals.add_client.name.placeholder")}
            />
            {formikAddClient.touched.name && formikAddClient.errors.name && (
              <span className="add-client-body__alert">
                {formikAddClient.errors.name}
              </span>
            )}
          </div>
          <div className="add-client-body__input-container">
            <InputLabel htmlFor="custom-text-field" required>
              {t("modals.add_client.surname.label")}
            </InputLabel>
            <TextField
              className="add-client-body__input"
              name="surName"
              type="text"
              value={formikAddClient.values.surName}
              onChange={formikAddClient.handleChange}
              onBlur={formikAddClient.handleBlur}
              required
              placeholder={t("modals.add_client.surname.placeholder")}
            />
            {formikAddClient.touched.surName &&
              formikAddClient.errors.surName && (
                <span className="add-client-body__alert">
                  {formikAddClient.errors.surName}
                </span>
              )}
          </div>
          <div className="add-client-body__input-container">
            <InputLabel htmlFor="custom-text-field" required>
              {t("modals.add_client.email.label")}
            </InputLabel>
            <TextField
              className="add-client-body__input"
              name="email"
              type="email"
              value={formikAddClient.values.email}
              onChange={formikAddClient.handleChange}
              onBlur={formikAddClient.handleBlur}
              required
              placeholder={t("modals.add_client.email.placeholder")}
            />
            {formikAddClient.touched.email && formikAddClient.errors.email && (
              <span className="add-client-body__alert">
                {formikAddClient.errors.email}
              </span>
            )}
          </div>
          <div className="add-client-body__input-container">
            <InputLabel htmlFor="custom-text-field" required>
              {t("modals.add_client.phone.label")}
            </InputLabel>
            <PhoneInput
              country={"mx"}
              specialLabel=""
              placeholder={t("modals.add_client.phone.placeholder")}
              onChange={formikAddClient.handleChange("phone")}
              onBlur={formikAddClient.handleBlur("phone")}
              value={formikAddClient.values.phone}
            />
            {formikAddClient.touched.phone && formikAddClient.errors.phone && (
              <span className="add-client-body__alert">
                {formikAddClient.errors.phone}
              </span>
            )}
          </div>
        </div>
        <div className="add-client-footer">
          <button className="add-client-footer__cancel">
            {t("modals.add_client.cancel_button")}
          </button>
          <button
            className="add-client-footer__add"
            onClick={() => formikAddClient.handleSubmit()}
          >
            {client
              ? t("modals.add_client.edit_client_button")
              : t("modals.add_client.add_client_button")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
