import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getToken, getUserId } from "../../services/localstorage";
import { getUser, updateUserStatus } from "../../services/user";
import {
  VerificationForm,
  verificationSchema,
} from "../../views/verification/form";
import { UserAccountStatus } from "../../models/user/types";
import { AxiosError } from "axios";
import { verification } from "../../services/auth";
import { useFormik } from "formik";

export const useVerification = () => {
  const { t } = useTranslation(["verification", "routes"]);
  const { data } = useQuery(["user"], getUser);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [toastMsg, setToastMsg] = useState<{
    message: string;
    type: "error" | "success" | "warning";
  }>({ message: "", type: "warning" });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isChangePhoneModalOpen, setIsChangePhoneModalOpen] =
    useState<boolean>(false);
  const [resetFlag, setResetFlag] = useState(false);

  const { data: user } = useQuery(["profile"], getUser, {
    enabled: !!getToken() && !!getUserId(),
  });

  // Check if user has right status
  useEffect(() => {
    if (
      user &&
      user.status != UserAccountStatus.REGISTER_NO_ACTIVE &&
      user.status != UserAccountStatus.REGISTER_NO_ACTIVE_PHONE
    ) {
      navigate(
        "/" +
          t("results", {
            ns: "routes",
          })
      );
    }
  }, [user]);

  // Complete verification
  const fetchVerification = async (values: VerificationForm) => {
    if (!user || !data) return;

    // Send message if it is a incorrect code
    if (values.phoneCode !== data.codePhone) {
      setToastMsg({
        message: t("error_validation.incorrect_code"),
        type: "error",
      });
      setOpenAlert(true);
      return;
    }

    try {
      // Depending of the prev status it sends the next
      if (
        user.status === UserAccountStatus.REGISTER_NO_ACTIVE ||
        user.status === UserAccountStatus.INVITED
      ) {
        await updateUserStatus(UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL);
      }

      if (user.status === UserAccountStatus.REGISTER_NO_ACTIVE_PHONE) {
        await updateUserStatus(UserAccountStatus.ACTIVE);
      }

      // Send to profile route and reset profile query
      navigate("/" + t("profile", { ns: "routes" }));
      queryClient.invalidateQueries(["profile"]);
    } catch (error) {
      // Send message if something went wrong in the request
      if (error instanceof AxiosError) {
        setToastMsg({
          message: error.response?.data.message,
          type: "error",
        });
        setOpenAlert(true);
      }
    }
  };

  // Formik validation
  const formikVerification = useFormik<VerificationForm>({
    initialValues: {
      phoneCode: "",
    },
    validationSchema: verificationSchema,
    onSubmit: fetchVerification,
  });

  // Resent code action
  const resendCode = async () => {
    // Check if it pass the time
    const storedValue = localStorage.getItem("remainingSeconds");
    if (!storedValue || storedValue !== "0") {
      setIsModalOpen(true);
      return;
    }

    try {
      // Resend code and show modal success
      await verification();
      setToastMsg({
        message: t("resend_code_success"),
        type: "success",
      });
      setResetFlag(!resetFlag);
      setIsModalOpen(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setToastMsg({
          message: error.response?.data,
          type: "error",
        });
      } else {
        setToastMsg({
          message: t("error_validation.resend_error"),
          type: "error",
        });
      }
    } finally {
      setOpenAlert(true);
    }
  };

  return {
    t,
    formikVerification,
    isModalOpen,
    openAlert,
    setOpenAlert,
    toastMsg,
    resendCode,
    resetFlag,
    setIsModalOpen,
    data,
    isChangePhoneModalOpen,
    setIsChangePhoneModalOpen,
    setResetFlag,
    queryClient,
    user,
  };
};
