import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import Toast from "../components/toast/component";

interface AlertProps {
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<
    SetStateAction<{
      message: string;
      type: "success" | "error" | "warning";
    }>
  >;
  alertMessage: {
    message: string;
    type: "success" | "error" | "warning";
  };
}

export const AlertContext = createContext<AlertProps>({
  openAlert: false,
  setOpenAlert: () => false,
  setAlertMessage: () => ({ message: "", type: "warning" }),
  alertMessage: { message: "", type: "warning" },
});

export const AlertProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    type: "success" | "error" | "warning";
  }>({ message: "", type: "warning" });

  return (
    <>
      <AlertContext.Provider
        value={{ openAlert, setOpenAlert, alertMessage, setAlertMessage }}
      >
        <Toast open={openAlert} setOpen={setOpenAlert} type={alertMessage.type}>
          {alertMessage.message}
        </Toast>
        {children}
      </AlertContext.Provider>
    </>
  );
};
