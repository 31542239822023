import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Formik, FormikErrors } from "formik";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AddCarDetails } from "../../../lib/components/carTools/addCarDetails/AddCarDetails";
import { AddCarDocumentation } from "../../../lib/components/carTools/addCarDocumentation/AddCarDocumentation";
import { AddCarModel } from "../../../lib/components/carTools/addCarModel/AddCarModel";
import { AddCarPrice } from "../../../lib/components/carTools/addCarPrice/AddCarPrice";
import { AddCarImportantData } from "../../../lib/components/carTools/addCarImportantData/AddCarImportantData";
import {
  CarStatus,
  Deactivated,
  ValidateInfo,
  DocInsuranceCover,
} from "../../../models/car/types";
import navbarImages from "../../../assets/images/navbar/index";
import { TranslationContext } from "../../driverReservation/show/component";
import { carSchema } from "./form";
import "./AddCar.scss";
import { CarForm, LocationState } from "./types";
import { CreateCarDTO } from "../../../models/car/dtos";
import useCar from "./useAddCar";
import Toast from "../../../components/toast/component";
import { CarType, Transmission } from "../../../models/car/types";
import { getCar } from "../../../services/car";
import { useQuery } from "react-query";
import Loading from "../../../components/loading/component";
import AddCarCompleted from "../../../lib/components/carTools/addCarCompleted/component";
import { Helmet } from "react-helmet";

export const AddCar = () => {
  const location = useLocation();
  const { step, carId } = (location?.state as LocationState) || 0;
  const [activeStep, setActiveStep] = useState(step || 0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [formErrors, setFormErrors] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [enableMainAddress, setEnableMainAddress] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const { t } = useTranslation(["car"]);
  const navigate = useNavigate();

  const { data: createdCar, isLoading } = useQuery(
    ["car", carId],
    () => getCar(carId?.toString()),
    {
      enabled: !!carId,
    }
  );

  useEffect(() => {
    if (createdCar) {
      setActiveStep(createdCar.step ?? 0);
    }
  }, [createdCar]);
  // Default suggested rules
  const SUGGESTED_RULES = `- ${t(
    "add.car_documentation.rules.suggested_rules.no_pets"
  )} 🐕\n- ${t(
    "add.car_documentation.rules.suggested_rules.no_smoking"
  )} 🚬\n- ${t(
    "add.car_documentation.rules.suggested_rules.no_eating"
  )} 🍕\n- ${t(
    "add.car_documentation.rules.suggested_rules.clean_car"
  )} ✨\n- ${t("add.car_documentation.rules.suggested_rules.tank")} ⛽️`;

  // Step names
  const steps = [
    t("add.steps.basic_data"),
    t("add.steps.important_data"),
    t("add.steps.details"),
    t("add.steps.price_and_availability"),
    t("add.steps.documents"),
  ];

  const {
    sendCarData,
    updateCarData,
    errorMsg,
    createdCarId,
    setErrorMsg,
    validateFields,
    setCreatedCarId,
  } = useCar({ carId });

  // Array of sections
  const stepView = [
    <AddCarModel formErrors={formErrors} />,
    <AddCarImportantData
      formErrors={formErrors}
      carId={createdCarId || carId?.toString() || ""}
    />,
    <AddCarDocumentation
      formErrors={formErrors}
      carId={createdCarId || carId?.toString() || ""}
    />,
    <AddCarPrice
      formErrors={formErrors}
      enableMainAddress={enableMainAddress}
      setEnableMainAddress={setEnableMainAddress}
      carId={createdCarId || carId?.toString() || ""}
    />,
    <AddCarDetails carId={createdCarId || carId?.toString() || ""} />,
  ];

  const StepperSx = {
    textAlign: "center",
    "& .MuiStep-root": {
      width: "200px",
      minWidth: "200px",
      "& .MuiStepConnector-root": {
        left: "calc(-53% + 20px)",
        right: "calc(47% + 20px)",
        zIndex: "-1",
        ".MuiStepConnector-line": {
          marginTop: "34px",
          borderColor: "#37C2CB",
        },
      },
      ".MuiStepIcon-root:not(.Mui-active)": {
        circle: {
          stroke: "#B72446",
          fill: "#fff",
        },
        ".MuiStepIcon-text": {
          fill: "#000",
        },
      },
    },
  };

  const TypographySx = {
    zIndex: "2",
    background: "#FFF",
    padding: "0 15px",
    color: "#13283A",
    whiteSpace: "nowrap",
  };

  const labelStyle = {
    marginTop: "10px",
  };

  // When submit move to next section
  const validateForm = (
    submitFn: (e?: FormEvent<HTMLFormElement> | undefined) => void,
    errors: FormikErrors<CarForm>,
    values: CarForm
  ) => {
    const passValidation = validateFields(
      activeStep,
      values,
      setFormErrors,
      errors
    );
    if (!passValidation) return;
    setLoading(true);
    setErrorMsg(null);
    setFormErrors(Object.keys(errors));
    handleNext(passValidation, values);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  // Check if validation success to move to next step
  const handleNext = (validated: boolean, values: CarForm) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (!!errorMsg && !validated) return;
    fetchCar(values);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Submit form data
  const fetchCar = async (values: CarForm) => {
    if (!createdCarId && !carId) {
      // create new car with first step data
      const response = await sendCarData(values as CreateCarDTO);
      if (!response) {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
        setOpenAlert(true);
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (activeStep === 0) {
      const newValues = {
        brand: values.brand,
        model: values.model,
        year: values.year,
        version: values.version,
        userId: 0,
        status: CarStatus.INCOMPLETE,
        extraAddresses: {},
      };
      const response = await updateCarData({
        values: newValues,
        step: activeStep,
      });
      if (!response) {
        setOpenAlert(true);
        return;
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (activeStep === 1) {
      const response = await updateCarData({
        values,
        step: activeStep,
        mainAddress: { enable: false },
      });
      if (!response) {
        setOpenAlert(true);
        return;
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (activeStep === 3) {
      // extra address, main address as an option and blocked dates

      const response = await updateCarData({
        values,
        step: activeStep,
        mainAddress: { enable: enableMainAddress },
      });
      if (!response) {
        setOpenAlert(true);
        return;
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (activeStep === steps.length - 1) {
      // update car with the rest of the data
      const response = await updateCarData({
        values,
        step: activeStep,
        completed: true,
      });
      if (!response) {
        setOpenAlert(true);
        return;
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    const response = await updateCarData({ values, step: activeStep });
    if (!response) {
      setOpenAlert(true);
      return;
    }
    setLoading(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  if (isLoading) return <Loading height="60vh" />;
  return (
    <>
      <Helmet>
        <title>{t("seo.add.title")}</title>
        <meta name="description" content={t("seo.add.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/subir-mi-auto"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/subir-mi-auto"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/subir-mi-auto"
        />
      </Helmet>
      {activeStep === 4 && (
        <Toast open={openAlert} setOpen={setOpenAlert} type="error">
          {errorMsg}
        </Toast>
      )}
      {activeStep === 5 ? (
        <AddCarCompleted
          carId={createdCarId || carId?.toString() || ""}
          setActiveStep={setActiveStep}
          setCreatedCarId={setCreatedCarId}
        />
      ) : (
        <TranslationContext.Provider value={t}>
          <Formik
            initialValues={
              !createdCar
                ? {
                    userId: 0,
                    brand: "",
                    model: "",
                    version: "",
                    year: "",
                    plate: "",
                    niv: "",
                    transmission: Transmission.AUTOMATIC,
                    type: CarType.COMPACT,
                    numSeats: 0,
                    numBags: 0,
                    isEcoFriendly: 0,
                    price: "",
                    questionnaireAnswers: "000",
                    characteristicsList: "",
                    status: CarStatus.INCOMPLETE,
                    description: "",
                    rules: SUGGESTED_RULES,
                    commission: "",
                    isReadyByHost: Deactivated.READY,
                    mainAddress: {
                      geometry: {
                        location: {
                          lat: "20.706165599669397",
                          lng: "-103.40390591631164",
                        },
                      },
                    },
                    extraAddresses: {},
                    blockDates: [],
                    insuranceCharge: "",
                    depositAmount: "",
                    homeDelivery: { enable: true, price: "" },
                    experiences: "",
                    docVehicleLicense: ValidateInfo.NOT_UPLOADED,
                    docInsuranceCover: DocInsuranceCover.NOT_UPLOADED,
                  }
                : {
                    ...createdCar,
                    rules:
                      createdCar.rules !== ""
                        ? createdCar.rules
                        : SUGGESTED_RULES,
                    extraAddresses: {},
                  }
            }
            validationSchema={carSchema}
            onSubmit={fetchCar}
          >
            {({ handleSubmit, errors, values }) => (
              <div className="add-car">
                <div className="add-cars__join-container">
                  <div className="a-cars__title-container">
                    <div className="a-cars__route">
                      <p className="a-cars__route__first">
                        {t("index.manages")}
                      </p>
                      <img
                        src={navbarImages.arrowRight}
                        className="a-cars__route__arrow"
                      />
                      <p className="a-cars__route__second">{t("index.cars")}</p>
                      <img
                        src={navbarImages.arrowRight}
                        className="a-cars__route__arrow"
                      />
                      <p className="a-cars__route__second">
                        {t("add.add_car")}
                      </p>
                    </div>
                    <h1 data-testid="my-cars-title" className="a-cars__title">
                      {t("add.add_car")}
                    </h1>
                  </div>
                </div>
                <div className="back-menu mb-5">
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{ overflow: "hidden", ...StepperSx }}
                  >
                    {steps.map((label) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <Typography align="center" sx={TypographySx}>
                            {label}
                          </Typography>
                          <StepLabel
                            {...labelProps}
                            sx={labelStyle}
                          ></StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
                <article className="add-car-container">
                  {stepView[activeStep]}
                  {errorMsg && (
                    <p
                      data-testid="error-message"
                      style={{ color: "#d02a2a", textAlign: "center" }}
                    >
                      Error: {errorMsg}
                    </p>
                  )}
                  <Box className="principal-btn-container">
                    {activeStep === steps.length - 1 ? (
                      <Button
                        onClick={() => {
                          setLoading(true);
                          handleSubmit();
                        }}
                        data-testid="next-step"
                        className="btn btn-orange "
                        disabled={loading}
                      >
                        {t("add.finish")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          validateForm(handleSubmit, errors, values)
                        }
                        data-testid="next-step"
                        className="btn btn-orange align-items-center"
                        disabled={loading}
                      >
                        {t("add.continue")}
                      </Button>
                    )}
                    <Button
                      color="inherit"
                      className="btn btn-back"
                      hidden={activeStep === 0}
                      onClick={handleBack}
                      disabled={loading}
                    >
                      {t("add.back")}
                    </Button>
                    <Button
                      color="inherit"
                      className="btn btn-back"
                      hidden={activeStep !== 0}
                      onClick={() => {
                        navigate(
                          `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                            ns: "routes",
                          })}`
                        );
                      }}
                      disabled={loading}
                    >
                      {t("add.cancel")}
                    </Button>
                  </Box>
                </article>
              </div>
            )}
          </Formik>
        </TranslationContext.Provider>
      )}
    </>
  );
};
