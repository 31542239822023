import { useFormik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import * as Yup from "yup";
import {
  updateCodeEmail,
  updateUserEmail,
  updateUserStatus,
} from "../../../services/user";
import { useQueryClient } from "react-query";
import { ModalProps } from "../types";
import { User, UserAccountStatus } from "../../../models/user/types";
import { useTranslation } from "react-i18next";
import Loading from "../../loading/component";

interface modalEmailForm {
  email: string;
}

interface modalEmailProps extends ModalProps {
  user: User;
}

const modalEmailModelSchema: Yup.SchemaOf<modalEmailForm> = Yup.object().shape({
  email: Yup.string().email().required(),
});

export const ChangeEmailModal: FC<modalEmailProps> = ({
  user,
  isOpen,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["modals"]);

  const fetchEmail = async (value: modalEmailForm) => {
    const response = await updateUserEmail(value.email);

    if (!response || response.status != 200) return;
    switch (user.status) {
      case UserAccountStatus.INVITED:
      case UserAccountStatus.REGISTER_NO_ACTIVE_PHONE:
        await updateUserStatus(UserAccountStatus.REGISTER_NO_ACTIVE);
        await updateCodeEmail();
        break;
      case UserAccountStatus.ACTIVE:
        await updateUserStatus(UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL);
        await updateCodeEmail();
        break;
      default:
        await updateCodeEmail();
        break;
    }
    setOpen();
    queryClient.resetQueries(["profile"]);
  };

  const formikEmailModal = useFormik<modalEmailForm>({
    initialValues: {
      email: "",
    },
    validationSchema: modalEmailModelSchema,
    onSubmit: fetchEmail,
  });

  return (
    <Modal show={isOpen} className="change-email-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.crossBlack} alt="" />
        </button>
        <h2 id="change_email_modal_text">{t("change_email.title")}</h2>
      </div>
      <div className="modal-body">
        <h3>{t("change_email.email")}</h3>
        <div className="input-form">
          <div className="d-flex">
            <input
              name="email"
              id="email_input"
              type="email"
              value={formikEmailModal.values.email}
              onChange={formikEmailModal.handleChange("email")}
              className="email-input modal-change-email"
              placeholder="Escribe aquí tu correo"
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          id="btn_change_email"
          type="button"
          onClick={() => {
            if (!formikEmailModal.isSubmitting) formikEmailModal.handleSubmit();
          }}
          disabled={formikEmailModal.isSubmitting}
          className="btn btn-orange"
        >
          {!formikEmailModal.isSubmitting ? (
            <p>{t("change_email.change")}</p>
          ) : (
            <Loading height="1vh" color="#fff" />
          )}
        </button>
      </div>
    </Modal>
  );
};
