import { SetStateAction, Dispatch, useRef, useContext } from "react";
import { Modal } from "@mui/material";
import "./CarDomainStatus.scss";
import { useTranslation } from "react-i18next";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Car,
  CarStatus,
  Deactivated,
  StatusVehicleLicense,
  ValidateInfo,
} from "../../../models/car/types";
import {
  DOCUMENTS_ICON_STATUS,
  HAS_GPS_ICON_STATUS,
} from "../../carStatusInfo/component";
import carImagesIndex from "../../../assets/images/car/index";
import navbarImages from "../../../assets/images/navbar/index";
import { useStatus } from "../../../hooks/car/show/useStatus";
import { DomainContext } from "../../../providers/domainProvider";
import { usePowerStatus } from "../../../hooks/car/show/usePowerStatus";

type CarDomainStatus = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  car: Car;
};

export const DOCUMENTS_POWER_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "20px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "20px" }}
    />
  ),
  2: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "20px" }}
    />
  ),
  3: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "20px" }}
    />
  ),
  4: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "20px" }}
    />
  ),
};

export const CarDomainStatus = ({ open, setOpen, car }: CarDomainStatus) => {
  const { t } = useTranslation(["car"]);
  const { settings } = useContext(DomainContext);
  const {
    DOCUMENTS_STATUS,
    CAR_STATUS,
    HAS_GPS_STATUS,
    docVehicleLicenseAboutToExpire,
    docVehicleLicenseExpired,
    docInsuranceAboutToExpire,
    docInsuranceExpired,
  } = useStatus(car);
  const { DOCUMENTS_STATUS_POWER, CAR_STATUS_POWER, HAS_GPS_STATUS_POWER } =
    usePowerStatus(car);

  const circleRomioIconColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED ||
      car.status === CarStatus.INCOMPLETE
      ? "#EB5757"
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? "#67E23B"
      : "#FFAB03"
  );

  const StatusRomioLabel = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? t("index.car_status.disabled")
      : car.status === CarStatus.INCOMPLETE
      ? t("index.car_status.incomplete")
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? t("index.car_status.active")
      : t("index.car_status.not_active")
  );

  const circlePowerIconColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? "#D1D1D1"
      : car.status === CarStatus.PENDING || car.status === CarStatus.REJECTED
      ? "#2E7D32"
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? "#2E7D32"
      : "#C62828"
  );

  const StatusPowerLabel = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? t("index.car_status.disabled")
      : car.status === CarStatus.PENDING || car.status === CarStatus.REJECTED
      ? t("index.car_status.active")
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? t("index.car_status.active")
      : t("index.car_status.incomplete")
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="car-domain-status-modal"
    >
      <div className="car-domain-status-container">
        <div className="car-domain-status-header">
          <h4 className="car-domain-status-header__title">
            {t("index.modals.domain_status.title")}
          </h4>
          <div className="btn-close-container">
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
        </div>
        <div className="car-domain-status-body">
          <div className="car-domain-status-body__subtitles">
            <h5>{`${car.brand} ${car.model} ${car.year}`}</h5>
            <p>
              <strong>{t("index.modals.domain_status.plates")}</strong>{" "}
              {car.plate}
            </p>
          </div>

          <div className="car-domain-status-body__container">
            <section className="car-domain-status-body__power">
              <div className="car-domain-status-body__status">
                <img
                  src={navbarImages[settings.name as keyof typeof navbarImages]}
                  className="car-domain-status-body__status__logo"
                />
                <div className="car-domain-status-body__status__chip">
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      color: circlePowerIconColor.current,
                    }}
                    className="car-domain-status-body__status__icon"
                  />
                  <p>{StatusPowerLabel.current}</p>
                </div>
              </div>
              <div className="car-domain-status-body__doc__status">
                <div className="car-domain-status-body__status__item">
                  {DOCUMENTS_POWER_ICON_STATUS[car.status]}
                  <p>
                    {t("index.status.car_info")}:{" "}
                    {CAR_STATUS_POWER.current[car.status]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__item">
                  {docVehicleLicenseAboutToExpire.current ? (
                    <img
                      src={carImagesIndex.not_uploaded}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : docVehicleLicenseExpired.current ? (
                    <img
                      src={carImagesIndex.refused}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : (
                    DOCUMENTS_POWER_ICON_STATUS[car.docVehicleLicense]
                  )}
                  <p>
                    {t("index.status.vehicle_license_document")}:
                    {docVehicleLicenseAboutToExpire.current
                      ? t("index.status.about_to_expire")
                      : docVehicleLicenseExpired.current
                      ? t("index.status.expired")
                      : DOCUMENTS_STATUS_POWER.current[car.docVehicleLicense]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__divider">
                  <hr style={{ width: "30%" }} />
                  {t("index.status.recommended")}
                  <hr style={{ width: "30%" }} />
                </div>
                <div className="car-domain-status-body__status__item">
                  {docInsuranceAboutToExpire.current ? (
                    <img
                      src={carImagesIndex.not_uploaded}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : docInsuranceExpired.current ? (
                    <img
                      src={carImagesIndex.refused}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : (
                    DOCUMENTS_POWER_ICON_STATUS[car.docInsuranceCover]
                  )}
                  <p>
                    {t("index.status.insurance_docuement")}:
                    {docInsuranceAboutToExpire.current
                      ? t("index.status.about_to_expire")
                      : docInsuranceExpired.current
                      ? t("index.status.expired")
                      : DOCUMENTS_STATUS_POWER.current[car.docInsuranceCover]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__item">
                  {HAS_GPS_ICON_STATUS[car.hasGPS]}
                  <p>{HAS_GPS_STATUS_POWER.current[car.hasGPS]}</p>
                </div>
              </div>
              <p className="car-domain-status-body__info">
                {t("index.modals.domain_status.power_status")}
              </p>
            </section>
            <section className="car-domain-status-body__romio">
              <div className="car-domain-status-body__status">
                <img
                  className="car-domain-status-body__status__logo"
                  src={navbarImages.romio}
                />
                <div className="car-domain-status-body__status__chip">
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      color: circleRomioIconColor.current,
                    }}
                    className="car-domain-status-body__status__icon"
                  />
                  <p>{StatusRomioLabel.current}</p>
                </div>
              </div>
              <div className="car-domain-status-body__doc__status">
                <div className="car-domain-status-body__status__item">
                  {DOCUMENTS_ICON_STATUS[car.status]}
                  <p>
                    {t("index.status.car_info")}:{" "}
                    {CAR_STATUS.current[car.status]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__item">
                  {docVehicleLicenseAboutToExpire.current ? (
                    <img
                      src={carImagesIndex.not_uploaded}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : docVehicleLicenseExpired.current ? (
                    <img
                      src={carImagesIndex.refused}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : (
                    DOCUMENTS_ICON_STATUS[car.docVehicleLicense]
                  )}
                  <p>
                    {t("index.status.vehicle_license_document")}:
                    {docVehicleLicenseAboutToExpire.current
                      ? t("index.status.about_to_expire")
                      : docVehicleLicenseExpired.current
                      ? t("index.status.expired")
                      : DOCUMENTS_STATUS.current[car.docVehicleLicense]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__divider">
                  <hr style={{ width: "30%" }} />
                  {t("index.status.recommended")}
                  <hr style={{ width: "30%" }} />
                </div>
                <div className="car-domain-status-body__status__item">
                  {docInsuranceAboutToExpire.current ? (
                    <img
                      src={carImagesIndex.not_uploaded}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : docInsuranceExpired.current ? (
                    <img
                      src={carImagesIndex.refused}
                      style={{ marginRight: "6px", fontSize: "14px" }}
                    />
                  ) : (
                    DOCUMENTS_ICON_STATUS[car.docInsuranceCover]
                  )}
                  <p>
                    {t("index.status.insurance_docuement")}:
                    {docInsuranceAboutToExpire.current
                      ? t("index.status.about_to_expire")
                      : docInsuranceExpired.current
                      ? t("index.status.expired")
                      : DOCUMENTS_STATUS.current[car.docInsuranceCover]}
                  </p>
                </div>
                <div className="car-domain-status-body__status__item">
                  {HAS_GPS_ICON_STATUS[car.hasGPS]}
                  <p>{HAS_GPS_STATUS.current[car.hasGPS]}</p>
                </div>
              </div>
              <p className="car-domain-status-body__info">
                {t("index.modals.domain_status.admin_status")}{" "}
                <a href="https://romio.mx/" target="_blank">
                  {t("index.modals.domain_status.romio_link")}
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};
