import { SetStateAction, Dispatch, useContext } from "react";
import { Modal } from "@mui/material";
import "./CarPricing.scss";
import { useTranslation } from "react-i18next";
import navbarImages from "../../../assets/images/navbar/index";
import { DomainContext } from "../../../providers/domainProvider";
import { formatPrice } from "../../carResultCard/utils";

type CarPricingProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  commission: string;
  type: "insurance" | "price";
  firstPrice: string;
};

export const CarPricing = ({
  open,
  setOpen,
  commission,
  type,
  firstPrice,
}: CarPricingProps) => {
  const { t } = useTranslation(["car"]);
  const { settings } = useContext(DomainContext);
  const commissionValue =
    parseFloat(firstPrice || "0") *
    (parseFloat(settings.payment.applicationFee) / 100);
  const commissionRomioValue =
    parseFloat(firstPrice || "0") * (parseFloat(commission || "0") / 100);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="car-pricing-modal"
    >
      <div className="car-pricing-container">
        <div className="car-pricing-header">
          <h4 className="car-pricing-header__title">
            {t("add.modals.car_pricing.title")}
          </h4>
          <div className="btn-close-container">
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
        </div>
        <div className="car-pricing-body">
          <div className="car-pricing-body__subtitles">
            <h5>
              {t(
                type === "price"
                  ? "add.modals.car_pricing.subtitle_car_pricing"
                  : "add.modals.car_pricing.subtitle_car_insurance",
                {
                  pricing: formatPrice(parseFloat(firstPrice || "0")),
                }
              )}
            </h5>
          </div>

          <div className="car-pricing-body__container">
            <section className="car-pricing-body__power">
              <div className="car-pricing-body__status">
                <div className="car-pricing-body__status__chip">
                  <p>{settings.payment.applicationFee}%</p>
                </div>
                <img
                  src={navbarImages[settings.name as keyof typeof navbarImages]}
                  className="car-pricing-body__status__logo"
                />
              </div>
              <div className="car-pricing-body__doc__status">
                <div className="car-pricing-body__status__item">
                  <p>{t("add.modals.car_pricing.power_commission")}:</p>
                  <span>{formatPrice(commissionValue)}</span>
                </div>
                <div className="car-pricing-body__status__item">
                  <p>{t("add.modals.car_pricing.revenue")}:</p>
                  <span>
                    {formatPrice(
                      parseFloat(firstPrice || "0") - commissionValue
                    )}
                  </span>
                </div>
              </div>
            </section>
            <section className="car-pricing-body__romio">
              <div className="car-pricing-body__status">
                <div className="car-pricing-body__status__chip">
                  <p>{commission}%</p>
                </div>
                <img
                  className="car-pricing-body__status__logo"
                  src={navbarImages.romio}
                />
              </div>
              <div className="car-pricing-body__doc__status">
                <div className="car-pricing-body__status__item">
                  <p>{t("add.modals.car_pricing.romio_commission")}:</p>
                  <span>{formatPrice(commissionRomioValue)}</span>
                </div>
                <div className="car-pricing-body__status__item">
                  <p>{t("add.modals.car_pricing.revenue")}:</p>
                  <span>
                    {formatPrice(
                      parseFloat(firstPrice || "0") - commissionRomioValue
                    )}
                  </span>
                </div>
              </div>
            </section>
          </div>
          <div className="car-pricing-footer__container">
            <p className="car-pricing-footer__info">
              {t("add.modals.car_pricing.extra_info")}{" "}
            </p>
            <a href="https://romio.mx/" target="_blank">
              {t("add.modals.car_pricing.more_details")}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};
