import { FC, useRef, useState } from "react";
import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import "./ClientTripCard.scss";
import { Trip, TripStatus } from "../../../../models/trip/types";
import { getCar } from "../../../../services/car";
import TripDefaultImage from "../../driverReservationTools/tripDefaultImage/TripDefaultImage";
import carImages from "../../../../assets/images/car";
import { MinDriverCard } from "../../../../components/tripCards/MinDriverCard";
import { dateFormat } from "../../../helpers/textHandler";
import { AddressText } from "../../driverReservationTools/addressText/AddressText";
import { formatPrice } from "../../../../components/carResultCard/utils";
import { ClientCard } from "../clientCard/ClientCard";
import { Client } from "../../../../models/client/types";
import { getType } from "../../../../views/car/index/types";
import { useTypes } from "../../../../hooks/car/show/useTypes";

export const ClientTripCard: FC<{
  trip: Trip;
  client: Client;
}> = ({ trip }) => {
  const { t } = useTranslation(["driverReservation", "routes"]);
  const CAR_TYPES = useTypes();
  const { data: car } = useQuery(
    ["car", trip.carId],
    () => getCar(trip.carId.toString()),
    {
      enabled: !!trip.carId,
    }
  );

  const circleIconColor = useRef(
    trip.status === TripStatus.PAYED_NOT_AUTHORIZED
      ? "#E7E7E7"
      : trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? "#FFAB03"
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? "#f76f8e"
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? "#55DD55"
      : trip.status === TripStatus.FINISH_RESERVATION
      ? "#3CB2B9"
      : trip.status === TripStatus.CANCEL_REQUEST_HUESPED
      ? "#E97451"
      : trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? "#E97451"
      : "#EB5757"
  );

  const circleLabel = useRef(
    trip.status === TripStatus.PAYED_NOT_AUTHORIZED
      ? t("index.status.incomplete")
      : trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? t("index.status.pending")
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? t("index.status.next")
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? t("index.status.active")
      : trip.status === TripStatus.FINISH_RESERVATION
      ? t("index.status.finished")
      : trip.status === TripStatus.CANCEL_REQUEST_HUESPED
      ? t("index.status.refused")
      : trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? t("index.status.refused")
      : t("index.status.cancel")
  );

  if (!trip || !car) return null;

  return (
    <Link
      style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
      to={
        `/${t("manage", { ns: "routes" })}/${t("trips", {
          ns: "routes",
        })}/` + trip.tripCode
      }
      className="list-group-item-trips-client"
    >
      <div style={{ fontSize: "6px" }}>
        <Chip
          icon={
            <CircleIcon
              fontSize="inherit"
              style={{
                color: circleIconColor.current,
              }}
            />
          }
          label={circleLabel.current}
          sx={{
            position: "absolute",
            top: "8px",
            left: "8px",
            background: "white",
            height: "24px",
            zIndex: 4,
          }}
        />
      </div>
      <TripDefaultImage trip={trip} />
      <div className="trip-container">
        <div className="car-and-user-details">
          <div className="reservation-text">
            <h2>{`${car.brand} ${car.model} ${car.year}`}</h2>
            <p>
              {`${car.plate} |`}
              <img
                src={CAR_TYPES[car.type].src}
                className="reservation-text-icon"
              />
              {CAR_TYPES[car.type].label}
            </p>
          </div>

          <ClientCard userId={trip.driverUserId || 0} />
        </div>
        <div className="trip-data">
          <div className="reservation-details">
            <h2 data-testid="type-label" className="blue-text">
              {t("index.start")}
            </h2>
            <p className="dates">
              {dateFormat(trip.pickUpDate.toString(), trip.pickUpTime)}
            </p>
            <div className="direction">
              <AddressText
                addressId={trip.pickUpAddressId}
                alert={false}
                tripStatus={trip.status}
              />
            </div>
          </div>
          <div className="reservation-details">
            <h2 data-testid="type-label" className="blue-text">
              {t("index.end")}
            </h2>
            <p className="dates">
              {dateFormat(trip.returnDate.toString(), trip.returnTime)}
            </p>
            <div className="direction">
              <AddressText
                addressId={trip.pickUpAddressId}
                alert={false}
                tripStatus={trip.status}
              />
            </div>
          </div>
          <div className="reservation-details-total">
            <h2 data-testid="type-label" className="blue-text">
              {t("index.total")}
            </h2>
            <div className="total">
              {trip?.hostTake ? formatPrice(parseFloat(trip?.hostTake)) : ""}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
