import "./Navbar.scss";
import { FC, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavbarCollapse } from "../../lib/components/navbarTools/navbarCollapse/component";
import { NavbarMenu } from "../../lib/components/navbarTools/navbarMenu/component";
import navbarImages from "../../assets/images/navbar/index";
import { Link } from "react-router-dom";
import { AuthNavbarProps, NoAuthNavbarProps } from "./types";
import { useTranslation } from "react-i18next";
import {
  DocStatus,
  UserAccountStatus,
  UserStatus,
  UserType,
} from "../../models/user/types";
import { useNavbar } from "../../hooks/nav/useNavbar";
import { useWindowSize } from "../../hooks/windowSize";
import StatusAlert from "../../lib/components/navbarTools/statusAlert/StatusAlert";
import { KycStatus } from "../../views/profile/types";
import { DomainContext } from "../../providers/domainProvider";
import { AuthContext } from "../../providers/authProvider";
import { use } from "i18next";

export const Navbar: FC = () => {
  const { settings } = useContext(DomainContext);
  const { urlType } = useContext(AuthContext);
  const {
    t,
    data,
    matchSignup,
    matchVerify,
    windowSize,
    isLogged,
    burgerMenuShow,
    setBurgerMenuShow,
    setClicks,
  } = useNavbar();

  // Conditional status mini navbar
  const isAlert = () => {
    if (!!matchSignup) return;
    if (!!matchVerify) return;
    if (!data) return null;

    if (data.isBlocked === UserStatus.BLOCKED) {
      return <StatusAlert type="blocked" user={data} />;
    }

    if (
      data.docBankStatement !== DocStatus.VALIDATED ||
      data.docProofOfAddress !== DocStatus.VALIDATED ||
      data.docOfficialDocuments !== KycStatus.SUCCESS ||
      data.status !== UserAccountStatus.ACTIVE
    ) {
      return <StatusAlert type="requiresAtention" user={data} />;
    }

    return null;
  };

  return (
    <>
      <header id="driverNavBar">
        <div className="navbar-container nav-container" id="h_navbar_container">
          <nav className="navbar navbar-expand-lg navbar-light navbar-content">
            <div className="container-fluid">
              {isLogged && data ? (
                <AuthNavbar
                  user={data}
                  burgerShow={() => setBurgerMenuShow(!burgerMenuShow)}
                  show={burgerMenuShow}
                  routeType={urlType}
                />
              ) : (
                <NoAuthNavbar
                  burgerShow={() => setBurgerMenuShow(!burgerMenuShow)}
                  show={burgerMenuShow}
                />
              )}
              <NavbarMenu user={data} logged={isLogged} routeType={urlType} />
              {!isLogged && (
                <Link
                  data-testid="create-account-menu"
                  to={t("signup", { ns: "routes" })}
                  className="btn btn-create-account"
                >
                  {t("create_an_account")}
                </Link>
              )}
              {windowSize < 992 &&
                isLogged &&
                data?.type === UserType.DRIVER && (
                  <>
                    <Link
                      to={
                        "/" +
                        t("results", {
                          ns: "routes",
                        })
                      }
                      className="logo-center"
                    >
                      <img
                        id="min_logo"
                        src={
                          navbarImages[
                            (settings.name +
                              "Icon") as keyof typeof navbarImages
                          ]
                        }
                        title="imagen_centrada"
                      />
                    </Link>
                    {settings.routes.homeHost && (
                      <Link
                        data-testid="upload-car"
                        to={t("host", { ns: "routes" })}
                        className="btn btn-upload-car"
                        style={{
                          background:
                            "linear-gradient(to right,rgb(255, 127, 200),rgba(250, 189, 200, 1))",
                          color: "#fff",
                        }}
                      >
                        {t("un_auth_navbar_menu.upload_my_car")}
                      </Link>
                    )}
                  </>
                )}
              {windowSize < 992 &&
              isLogged &&
              data?.type === UserType.DRIVER_HOST &&
              urlType !== "driver" ? (
                <>
                  <div style={{ order: 3 }}>
                    <p className="host-label">
                      {t("auth_navbar_collapse.host.title")}
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ order: 3, display: "none" }}></div>
              )}
              <NavbarCollapse
                user={data}
                logged={isLogged}
                burgerMenuShow={burgerMenuShow}
                routeType={urlType}
                actionClick={() => setClicks((prev) => !prev)}
              />
            </div>
          </nav>
        </div>
      </header>

      {isLogged && isAlert()}
    </>
  );
};

const NoAuthNavbar: FC<NoAuthNavbarProps> = ({ burgerShow, show }) => {
  const { settings } = useContext(DomainContext);
  const { t } = useTranslation(["routes"]);
  return (
    <>
      <Link
        to={
          "/" +
          t("results", {
            ns: "routes",
          })
        }
        className="logo-center"
      >
        <img
          id="main_logo"
          src={navbarImages[settings.name as keyof typeof navbarImages]}
          title="imagen_centrada"
        />
        <img
          id="min_logo"
          src={
            navbarImages[(settings.name + "Icon") as keyof typeof navbarImages]
          }
          title="imagen_centrada"
        />
      </Link>
      <button
        type="button"
        data-testid="burger-button"
        className="navbar-toggler p-0 menu-button border-0"
        onClick={() => burgerShow()}
      >
        <img
          className="burger-menu"
          src={show ? navbarImages.crossBlack : navbarImages.burgerBlack}
          id="menu"
          alt=""
        />
      </button>
    </>
  );
};

const AuthNavbar: FC<AuthNavbarProps> = ({
  burgerShow,
  show,
  routeType,
  user,
}) => {
  const { settings } = useContext(DomainContext);
  const { t } = useTranslation(["navbar", "routes"]);
  const windowSize = useWindowSize();

  return (
    <>
      {((windowSize > 990 && user.type === UserType.DRIVER) ||
        user.type === UserType.DRIVER_HOST) && (
        <div className="logo-container">
          <Link
            to={
              routeType === "driver"
                ? "/" +
                  t("results", {
                    ns: "routes",
                  })
                : t("my_cars", { ns: "routes" })
            }
            className="logo-desktop"
            aria-label="go to homepage"
          >
            <img
              src={
                routeType === "driver"
                  ? navbarImages[settings.name as keyof typeof navbarImages]
                  : navbarImages[settings.name as keyof typeof navbarImages]
              }
              className="logo_orange"
              aria-hidden="true"
            />
          </Link>
          {windowSize > 990 &&
            user.type === UserType.DRIVER_HOST &&
            routeType !== "driver" && (
              <div style={{ order: 2 }}>
                <p className="host-label">
                  {t("auth_navbar_collapse.host.title")}
                  <img
                    src={navbarImages.hostEclipse}
                    alt=""
                    className="host-status-icon"
                  />
                </p>
              </div>
            )}
        </div>
      )}
      {user.type === UserType.DRIVER_HOST && (
        <Link
          to={
            "/" +
            t("results", {
              ns: "routes",
            })
          }
          style={{ order: 2 }}
        >
          <img
            src={
              routeType === "driver"
                ? navbarImages[settings.name as keyof typeof navbarImages]
                : navbarImages[settings.name as keyof typeof navbarImages]
            }
            className="logo"
            id="logo2"
            style={{ maxWidth: "160px" }}
          />
        </Link>
      )}
      <button
        type="button"
        className="navbar-toggler p-0 menu-button border-0"
        data-testid="burger-button"
        onClick={() => burgerShow()}
      >
        <img
          className="burger-menu"
          src={
            show
              ? routeType === "driver"
                ? navbarImages.crossBlack
                : navbarImages.crossBlack
              : routeType === "driver"
              ? navbarImages.burgerBlack
              : navbarImages.burgerBlack
          }
          alt=""
        />
      </button>
    </>
  );
};
