import { Box, TextField, Tooltip } from "@mui/material";
import a_carsImages from "../../../../../assets/images/a_cars";
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  BlockedDatesProps,
  DateBlock,
} from "../../../../../views/car/add/types";
import { useBlockedDates } from "./useBlockedDates";
import dayjs from "dayjs";

export const BlockedDates = ({ carId }: BlockedDatesProps) => {
  const {
    t,
    values,
    matchesMobile,
    dates,
    handleBlockDates,
    handleEraseBlockDate,
    storeDates,
    setDates,
    setStoreDates,
  } = useBlockedDates({ carId });

  return (
    <>
      <div className="disponibility-text-container">
        <p>
          {t("add.car_price.availability.select_on_calendar")}{" "}
          <span>{t("add.car_price.availability.not_available")}</span>
        </p>
      </div>
      <div className="date-container">
        {matchesMobile ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="mobile"
              value={dates}
              disablePast
              className="date-container-date-picker"
              inputFormat="DD/MM/YYYY"
              onChange={(newValue) => {
                setStoreDates(newValue);
                setDates(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
              shouldDisableDate={(date) =>
                values.blockDates?.some((dates) =>
                  dates.dates.some(
                    (dateItem) =>
                      dayjs(date).format("DD-MM-YYYY") ===
                      dayjs(dateItem).format("DD-MM-YYYY")
                  )
                ) || false
              }
              showToolbar={false}
              componentsProps={{
                actionBar: {
                  actions: undefined,
                },
              }}
            />
          </LocalizationProvider>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={dates}
              disablePast
              inputFormat="DD/MM/YYYY"
              onChange={(newValue) => {
                setStoreDates(newValue);
                setDates(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
              shouldDisableDate={(date) =>
                values.blockDates?.some((dates) =>
                  dates.dates.some(
                    (dateItem) =>
                      dayjs(date).format("DD-MM-YYYY") ===
                      dayjs(dateItem).format("DD-MM-YYYY")
                  )
                ) || false
              }
            />
          </LocalizationProvider>
        )}
      </div>
      <div id="input_dates_container" className="input-dates-container">
        <button
          className="btn btn-orange"
          id="addDate"
          type="button"
          onClick={() => handleBlockDates(storeDates)}
        >
          {t("add.car_price.availability.block")}
        </button>
        {values.blockDates && values.blockDates.length > 0 && (
          <h2 className="dates-text">
            {t("add.car_price.availability.blackout_dates")}
          </h2>
        )}
        {values.blockDates?.map((date: DateBlock, index: number) => (
          <div
            id="dates-table${disableDatesCounter}"
            className="dates-table"
            key={index}
          >
            <div className="date-container">
              <p>{t("add.car_price.availability.from")}</p>
              <input
                id="start_date${disableDatesCounter}"
                className="input-date s"
                value={date.startDate}
                disabled
              />
            </div>
            <div className="date-container">
              <p>{t("add.car_price.availability.to")}</p>
              <input
                id="end_date${disableDatesCounter}"
                className="input-date e"
                value={date.endDate}
                disabled
              />
            </div>
            <button
              type="button"
              className="btn btn-erase"
              onClick={() => handleEraseBlockDate(index)}
            >
              <Tooltip title="borrar?">
                <img src={a_carsImages.erase} alt="" />
              </Tooltip>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};
