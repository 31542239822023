import { KycStatus } from "../../views/profile/types";

export interface User {
  id: number;
  type: UserType;
  status?: UserAccountStatus;
  name: string;
  surName: string;
  email: string;
  phone: string;
  codeMail: string;
  codePhone: string;
  codeDate: string;
  isLogged?: number;
  lastLogTime: string;
  inviteCode: string;
  createDate: string; // Signuptime
  stripeCustomerId?: string;
  docBankStatement: DocStatus;
  docProofOfAddress: DocStatus;
  isBlocked: UserStatus; //0 | 1 | 2;
  bankStatementComments?: string;
  proofOfAddressComments?: string;
  docOfficialDocuments: KycStatus;
  trustedUser: TrustedUser;
  refreshToken?: string; // Not used
  moffinReportId?: number;
}

export enum DocStatus {
  NOT_UPLOADED,
  PENDING,
  VALIDATED,
  REFUSED,
}

export enum TrustedUser {
  FALSE,
  TRUE,
}

export enum UserStatus {
  VALID,
  BLOCKED,
  PROCESS_DELETE,
}

export enum UserType {
  DRIVER,
  DRIVER_HOST,
}

export enum UserAccountStatus {
  REGISTER_NO_ACTIVE,
  REGISTER_NO_ACTIVE_PHONE,
  REGISTER_NO_ACTIVE_EMAIL,
  ACTIVE,
  INVITED,
}
