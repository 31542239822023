import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Car, CarStatus, Deactivated } from "../../models/car/types";
import "../../views/car/index/styles.scss";
import { Popover, List, ListItem, Button } from "@mui/material";
import CarOptions from "../modals/carOptions/component";
import { Link, useNavigate } from "react-router-dom";
import CarInsurance from "../modals/carInsurance/component";
import { CarDomainStatus } from "../modals/carDomainStatus/CarDomainStatus";
import { updateDeactivation } from "../../services/car";
import { getTripByCarID } from "../../services/trip";
import { Trip, TripStatus } from "../../models/trip/types";
import Toast from "../toast/component";
import { useQuery, useQueryClient } from "react-query";
import { getUser } from "../../services/user";
import { UserType } from "../../models/user/types";
import { getHost } from "../../services/host";
import { encryptId } from "../../lib/helpers/encrypt";

const CarMoreOptions = ({
  anchorEl,
  setAnchorEl,
  car,
}: {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  car: Car;
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [openInsurance, setOpenInsurance] = useState(false);
  const [openCarDomainStatus, setOpenCarDomainStatus] = useState(false);
  const [type, setType] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isBlockedForDelete, setIsBlockedForDelete] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const { data: user } = useQuery(["profile"], getUser);
  const { data: hostCommercial } = useQuery(["hostC"], () => getHost());
  const queryClient = useQueryClient();
  const { t } = useTranslation(["car", "routes"]);
  const navigate = useNavigate();

  const getAction = (action: string) => {
    setType(action);
    if (action === "insurance") {
      setOpenInsurance(true);
    } else if (action === "check-status") {
      setOpenCarDomainStatus(true);
    } else {
      setOpenOptions(true);
    }
    setAnchorEl(null);
  };

  const deleteSelectedCar = async () => {
    setIsLoading(true);
    try {
      await updateDeactivation({
        id: car.id,
        status: Deactivated.REMOVED,
      });
      queryClient.resetQueries(["cars"]);
      setOpenOptions(false);
    } catch (error) {
      setErrorMsg(t("index.options.delete_error_message"));
      setOpenAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const pauseSelectedCar = async () => {
    setIsLoading(true);
    try {
      await updateDeactivation({
        id: car.id,
        status: type === "pause" ? Deactivated.DISABLED : Deactivated.READY,
      });
      queryClient.resetQueries(["cars"]);
      setOpenOptions(false);
    } catch (error) {
      setErrorMsg(t("index.options.pause_error_message"));
      setOpenAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getCarTrips = async () => {
    try {
      const data = await getTripByCarID(car.id);
      if (!data) return;
      const result = data.some(
        (item: Trip) =>
          item.status === TripStatus.ACTIVE_RESERVATION ||
          item.status === TripStatus.ACCEPT_RESERVATION ||
          item.status === TripStatus.SEND_REQUEST_RESERVATION
      );
      setIsBlockedForDelete(result);
    } catch (_) {
      setIsBlockedForDelete(true);
    }
  };

  useEffect(() => {
    getCarTrips();
  }, []);

  return (
    <>
      {errorMsg && (
        <Toast open={openAlert} setOpen={setOpenAlert} type="error">
          {errorMsg}
        </Toast>
      )}
      <CarOptions
        setOpen={setOpenOptions}
        open={openOptions}
        type={type}
        action={type === "delete" ? deleteSelectedCar : pauseSelectedCar}
        isLoading={isLoading}
        isEmpty={isBlockedForDelete}
      />
      <CarInsurance
        open={openInsurance}
        setOpen={setOpenInsurance}
        car={car}
        hostCommercial={hostCommercial}
      />

      <CarDomainStatus
        open={openCarDomainStatus}
        setOpen={setOpenCarDomainStatus}
        car={car}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <List onClick={(e: any) => getAction(e.target.dataset.action)}>
          {car.status !== CarStatus.INCOMPLETE ? (
            <>
              <ListItem sx={{ cursor: "pointer" }}>
                <Link
                  to={
                    `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                      ns: "routes",
                    })}/${t("edit", { ns: "routes" })}/` + car.id
                  }
                  style={{ color: "inherit", textDecoration: "none" }}
                  data-testid="edit-button"
                >
                  {t("index.view_edit")}
                </Link>
              </ListItem>
              <ListItem
                data-action={
                  car.isReadyByHost === Deactivated.DISABLED
                    ? "pause-remove"
                    : "pause"
                }
                sx={{ cursor: "pointer" }}
              >
                {car.isReadyByHost === Deactivated.DISABLED
                  ? t("index.options.remove_pause")
                  : t("index.options.pause_car")}
              </ListItem>
              {user?.type === UserType.DRIVER_HOST && (
                <ListItem data-action="insurance" sx={{ cursor: "pointer" }}>
                  {t("index.options.insurance_details")}
                </ListItem>
              )}

              <ListItem data-action="check-status" sx={{ cursor: "pointer" }}>
                {t("index.options.check_status")}
              </ListItem>

              <ListItem data-action="preview" sx={{ cursor: "pointer" }}>
                <Link
                  to={{
                    pathname: `/${t("details", { ns: "routes" })}/${encryptId(
                      car.id.toString() || "0"
                    )}`,
                    search: "?preview",
                  }}
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  {t("index.options.car_preview")}
                </Link>
              </ListItem>
            </>
          ) : (
            <ListItem>
              <Link
                to={{
                  pathname: `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                    ns: "routes",
                  })}/${t("upload_car", { ns: "routes" })}`,
                }}
                state={{
                  step: car?.step === 0 ? 1 : car?.step,
                  carId: car?.id,
                }}
                style={{ color: "inherit", textDecoration: "none" }}
                data-testid="complete-button"
              >
                {t("index.complete_car")}
              </Link>
            </ListItem>
          )}

          <ListItem
            data-action="delete"
            sx={{ cursor: "pointer" }}
            data-testid="delete-car-option"
          >
            {t("index.options.delete_car")}
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default CarMoreOptions;
