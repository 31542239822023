import { Trans, useTranslation } from "react-i18next";
import "./ClientKyc.scss";
import { Client, ClientKYCReport } from "../../../../models/client/types";
import { Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { DocStatus, UserStatus } from "../../../../models/user/types";
import { KycStatus } from "../../../../views/profile/types";
import CloseIcon from "@mui/icons-material/Close";
import { getFileDetailsFromPath } from "../../../../services/fileUploader";
import { useEffect, useState } from "react";
import UploadDropZone from "../../../../components/uploadDropZone/component";
import clientImages from "../../../../assets/images/client";
import dayjs from "dayjs";

export const ClientKYC = ({
  client,
  clientKYCReport,
}: {
  client: Client;
  clientKYCReport: ClientKYCReport;
}) => {
  const [paidKyc, setPaidKyc] = useState<boolean>(true);
  const { t } = useTranslation(["client", "routes"]);
  const [proofOfAddress, setProofOfAddress] = useState<
    { url: string; mime: string } | undefined
  >();
  const [bankStatement, setBankStatement] = useState<
    { url: string; mime: string } | undefined
  >();

  const getFileDetails = async (id: number) => {
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/comprobante_domicilio`,
      setProofOfAddress
    );
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/estado_cuenta`,
      setBankStatement
    );
  };

  useEffect(() => {
    if (client) {
      getFileDetails(client.id);
    }
  }, [client]);

  const clientId =
    client.docOfficialDocuments === KycStatus.PENDING ||
    client.docOfficialDocuments === KycStatus.SUCCESS ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>
          {t("show.client_security.completed")}
        </p>
      </div>
    ) : client.docOfficialDocuments === KycStatus.NOT_STARTED ? (
      <div className="status">
        <WarningAmberIcon color="error" />
        <p style={{ color: "#F9A825" }}>
          {t("show.client_security.incomplete")}
        </p>
      </div>
    ) : (
      <div className="status">
        <CloseIcon color="error" />
        <p style={{ color: "#C62828" }}>{t("show.client_security.refused")}</p>
      </div>
    );

  const clientLicense =
    client.docBankStatement === DocStatus.PENDING ||
    client.docBankStatement === DocStatus.VALIDATED ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>
          {t("show.client_security.completed")}
        </p>
      </div>
    ) : DocStatus.NOT_UPLOADED ? (
      <div className="status">
        <WarningAmberIcon color="error" />
        <p style={{ color: "#F9A825" }}>
          {t("show.client_security.incomplete")}
        </p>
      </div>
    ) : (
      <div className="status">
        <CloseIcon color="error" />
        <p style={{ color: "#C62828" }}>{t("show.client_security.refused")}</p>
      </div>
    );

  const clientProofAddress =
    client.docProofOfAddress === DocStatus.PENDING ||
    client.docProofOfAddress === DocStatus.VALIDATED ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>
          {t("show.client_security.completed")}
        </p>
      </div>
    ) : client.docProofOfAddress === DocStatus.NOT_UPLOADED ? (
      <div className="status">
        <WarningAmberIcon color="error" />
        <p style={{ color: "#F9A825" }}>
          {t("show.client_security.incomplete")}
        </p>
      </div>
    ) : (
      <div className="status">
        <CloseIcon color="error" />
        <p style={{ color: "#C62828" }}>{t("show.client_security.refused")}</p>
      </div>
    );

  return (
    <article className="client-kyc-container">
      <div className="client-kyc__information__title">
        <h2>{t("show.client_kyc.security_info")}</h2>
        <p>{t("show.client_kyc.info")}</p>
      </div>

      {paidKyc ? (
        <>
          <div className="client-kyc__main-status">
            <h3>{t("show.client_kyc.results")}</h3>
            <div className="client-kyc__status-item-container">
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.doc_extraction")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.proof_life")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.bio_match")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">{t("show.client_kyc.id")}</p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.alteration")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.curp_validation")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.credit_score")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.id_expiration")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.license_expiration")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.email_trust")}
                </p>
              </div>
              <div className="client-kyc__status-item">
                <Chip
                  icon={<CheckCircleOutlineIcon color="success" />}
                  label={"Confiado"}
                  className="status-item__chip"
                  sx={{
                    backgroundColor: "#E8F5E9",
                    color: "#2E7D32",
                    padding: "4px 16px",
                  }}
                />
                <p className="status-item__title">
                  {t("show.client_kyc.phone_trust")}
                </p>
              </div>
            </div>
          </div>
          <div className="dropdown-divider"></div>

          <div className="client-kyc__security-docs">
            <h3>{t("show.client_security.title")}</h3>
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_security.id")}</h4>
                {clientId}
              </div>
              <div className="docs-item-body">
                <img
                  src={clientKYCReport.documentsFront}
                  alt=""
                  className="kyc-images"
                />
                <p className="doc-position">
                  {t("show.client_security.front")}
                </p>
              </div>
              <div className="docs-item-body">
                <img
                  src={clientKYCReport.documentsBack}
                  alt=""
                  className="kyc-images"
                />
                <p className="doc-position">{t("show.client_security.back")}</p>
              </div>
            </div>
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_security.license")}</h4>
                {clientLicense}
              </div>
              <p className="decline">{t("show.client_security.decline")}</p>
              <div className="docs-item-body">
                {bankStatement && bankStatement.url && (
                  <UploadDropZone
                    allow="file,image"
                    fileDetails={{
                      url: bankStatement?.url || "",
                      type: bankStatement?.mime || "",
                    }}
                    height="120px"
                    path={{
                      fileName: "estado_cuenta",
                    }}
                  />
                )}
                <p className="doc-position">
                  {t("show.client_security.front")}
                </p>
              </div>
            </div>
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_security.proof_address")}</h4>
                {clientProofAddress}
              </div>
              <p className="decline">{t("show.client_security.decline")}</p>
              <div className="docs-item-body">
                {proofOfAddress && proofOfAddress.url && (
                  <UploadDropZone
                    allow="file,image"
                    fileDetails={{
                      url: proofOfAddress?.url || "",
                      type: proofOfAddress?.mime || "",
                    }}
                    height="120px"
                    path={{
                      fileName: "comprobante_domicilio",
                    }}
                  />
                )}
                <p className="doc-position">
                  {t("show.client_security.front")}
                </p>
              </div>
            </div>
          </div>

          <div className="dropdown-divider"></div>

          <div className="client-kyc__information">
            <h3>{t("show.client_information.title")}</h3>
            <div className="client-kyc__information-container">
              <div className="client-kyc__information-text">
                <div className="information-text-item">
                  <p>{clientKYCReport.name}</p>
                  <span>{t("show.client_information.full_name")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.fatherLastname}</p>
                  <span>{t("show.client_information.father_surname")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.motherLastname}</p>
                  <span>{t("show.client_information.mother_surname")}</span>
                </div>
                <div className="information-text-item">
                  <p>
                    {`${dayjs(clientKYCReport.birthDay, "DD/MM/YYYY").format(
                      "DD-MMMM-YYYY"
                    )} • ${clientKYCReport.age} años`}
                  </p>
                  <span>{t("show.client_information.birth_date")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.gender}</p>
                  <span>{t("show.client_information.gender")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.nationality}</p>
                  <span>{t("show.client_information.nationality")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.curp}</p>
                  <span>{t("show.client_information.curp")}</span>
                </div>
                <div className="information-text-item">
                  <p>
                    {clientKYCReport.typeDocument === "ELECTORAL_ID"
                      ? "INE"
                      : "Licencia de conducir"}
                  </p>
                  <span>{t("show.client_information.id")}</span>
                </div>
                <div className="information-text-item">
                  <p>{clientKYCReport.numberDocument}</p>
                  <span>{t("show.client_information.number_id")}</span>
                </div>
                {clientKYCReport.typeDocument === "ELECTORAL_ID" ? (
                  <div className="information-text-item">
                    <p>
                      {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                        "DD-MMMM-YYYY"
                      )}
                    </p>
                    <span>{t("show.client_information.id_expiration")}</span>
                  </div>
                ) : (
                  <div className="information-text-item">
                    <p>
                      {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                        "DD-MMMM-YYYY"
                      )}
                    </p>
                    <span>
                      {t("show.client_information.license_expiration")}
                    </span>
                  </div>
                )}
                <div className="information-text-item address">
                  <p>{clientKYCReport.address}</p>
                  <span>{t("show.client_information.address")}</span>
                </div>
              </div>
              <div className="client-kyc__information-images">
                <div className="information-images-container">
                  {clientKYCReport.biometricData.map(
                    (src: string, index: number) => (
                      <div className="information-images-item-container">
                        <div className="information-images-item">
                          <img src={src} alt="" />
                        </div>
                        <p>{`Foto ${index + 1}`}</p>
                      </div>
                    )
                  )}
                </div>
                <p>{t("show.client_information.bio_data")}</p>
              </div>
            </div>
          </div>

          <div className="dropdown-divider"></div>

          <div className="client-kyc__report-ids">
            <h3>{t("show.client_reports.reports")}</h3>
            <div className="client-kyc__report-ids-container">
              <Trans>
                <div className="report-ids-item">
                  <p>
                    {t("show.client_reports.moffin", {
                      moffinReportId: clientKYCReport.moffinReportId,
                    })}
                  </p>
                  <a href="">{t("show.client_reports.watch_report")}</a>
                </div>
                <div className="report-ids-item">
                  <p>
                    {t("show.client_reports.credit", {
                      creditScoreReportId: "123",
                    })}
                  </p>
                  <a href="">{t("show.client_reports.watch_report")}</a>
                </div>
                <div className="report-ids-item">
                  <p>
                    {t("show.client_reports.seon", { seonReportId: "123" })}
                  </p>
                  <a href="">{t("show.client_reports.watch_report")}</a>
                </div>
              </Trans>
            </div>
          </div>
        </>
      ) : (
        <div className="client-kyc__main-status not-paid-kyc">
          <h3>{t("show.client_kyc.results")}</h3>
          <div className="client-kyc__status-item-container">
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.doc_extraction")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.proof_life")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.bio_match")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">{t("show.client_kyc.id")}</p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.alteration")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.curp_validation")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.credit_score")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.id_expiration")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.license_expiration")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.email_trust")}
              </p>
            </div>
            <div className="client-kyc__status-item">
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"Confiado"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <p className="status-item__title">
                {t("show.client_kyc.phone_trust")}
              </p>
            </div>
          </div>
          <div className="modal-not-paid-kyc">
            <img src={clientImages.shield} alt="" className="kyc-shield" />
            <div className="modal-not-paid-kyc__body">
              <h4>{t("modals.not_paid_kyc.more_security")}</h4>
              <p>{t("modals.not_paid_kyc.pay")}</p>
            </div>
            <div className="modal-not-paid-kyc__footer">
              <button className="btn btn-plans">
                {t("modals.not_paid_kyc.plans")}
              </button>
            </div>
          </div>
        </div>
      )}
    </article>
  );
};
