import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { carBrands } from "../../../../models/car/dtos";
import { useAddCarModel } from "./useAddCarModel";

/**
 * ADD CAR MODEL - step 1: brand, model, year and version to create car
 */
export const AddCarModel = ({
  formErrors,
}: {
  formErrors: string[] | undefined;
}) => {
  const {
    t,
    brandsSuccess,
    values,
    handleCarSpecifications,
    brands,
    types,
    typesSuccess,
    years,
    yearsSuccess,
    versions,
    versionsSuccess,
  } = useAddCarModel();

  return (
    <div className="model-ubication" id="first_step">
      <div className="form-container">
        <div className="info-container">
          <h2 data-testid="model-and-ubication-title" className="title">
            {t("add.car_model_and_ubication.tell_us_about_your_car")}
          </h2>
          <h3>{t("add.car_model_and_ubication.select_and_add_features")}</h3>
          <p>{t("add.car_model_and_ubication.all_fields")}</p>
        </div>
        <form>
          <div className="form">
            <div className="generic-input">
              <label className="select-label">
                {t("add.car_model_and_ubication.brand.title")}
              </label>
              <FormControl fullWidth>
                <InputLabel id="brand">
                  {t("add.car_model_and_ubication.brand.your_cars_brand")}
                </InputLabel>
                {brandsSuccess && (
                  <Select
                    labelId="brand"
                    data-testid="select-brand"
                    id="brand"
                    label=""
                    value={values.brand}
                    onChange={(e) => {
                      handleCarSpecifications(e, "brand");
                    }}
                    disabled={!brandsSuccess}
                    className="dropdown"
                    autoFocus
                    error={formErrors?.includes("brand")}
                  >
                    {brands &&
                      brands?.map((brand: carBrands, index: number) => (
                        <MenuItem
                          data-testid={`select-brand-${index}`}
                          key={brand.cMarcaLarga}
                          value={brand.cMarcaLarga}
                        >
                          {brand.cMarcaLarga}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            </div>
            <div className="generic-input">
              <label className="select-label">
                {t("add.car_model_and_ubication.model.title")}
              </label>
              <FormControl fullWidth>
                <InputLabel id="model">
                  {t("add.car_model_and_ubication.model.your_cars_model")}
                </InputLabel>
                {typesSuccess && (
                  <Select
                    labelId="model"
                    id="model"
                    data-testid="select-model"
                    value={values.model}
                    onChange={(e) => {
                      handleCarSpecifications(e, "type");
                    }}
                    disabled={!typesSuccess}
                    className="dropdown"
                    label=""
                    required
                    error={formErrors?.includes("model")}
                    autoFocus
                  >
                    {types ? (
                      types?.map((type: string, index: number) => (
                        <MenuItem
                          key={type}
                          value={type}
                          data-testid={`select-model-${index}`}
                        >
                          {type}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={values.model}>{values.model}</MenuItem>
                    )}
                  </Select>
                )}
              </FormControl>
            </div>
            <div className="generic-input">
              <label className="select-label">
                {t("add.car_model_and_ubication.year.title")}
              </label>
              <FormControl fullWidth>
                <InputLabel id="year">
                  {t("add.car_model_and_ubication.year.your_cars_year")}
                </InputLabel>
                {yearsSuccess && (
                  <Select
                    labelId="year"
                    id="year"
                    data-testid="select-year"
                    value={values.year}
                    defaultValue={values.year}
                    onChange={(e) => {
                      handleCarSpecifications(e, "year");
                    }}
                    className="dropdown"
                    disabled={!yearsSuccess}
                    label=""
                    required
                    error={formErrors && formErrors.includes("year")}
                    autoFocus
                  >
                    {years ? (
                      years?.map((year: string, index: number) => (
                        <MenuItem
                          key={year}
                          value={year}
                          data-testid={`select-year-${index}`}
                        >
                          {year}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={values.year}>{values.year}</MenuItem>
                    )}
                  </Select>
                )}
              </FormControl>
            </div>
            <div className="generic-input">
              <label className="select-label">
                {t("add.car_model_and_ubication.version.title")}
              </label>
              <FormControl fullWidth>
                <InputLabel id="version">
                  {t("add.car_model_and_ubication.version.your_cars_version")}
                </InputLabel>
                {versionsSuccess && (
                  <Select
                    labelId="version"
                    data-testid="select-version"
                    id="version"
                    value={versionsSuccess ? values.version : ""}
                    onChange={(e) => {
                      handleCarSpecifications(e, "version");
                    }}
                    disabled={!versionsSuccess}
                    className="dropdown"
                    label=""
                    required
                    error={formErrors && formErrors.includes("version")}
                    autoFocus
                  >
                    {versions ? (
                      versions?.map((version: string, index: number) => (
                        <MenuItem
                          key={version}
                          value={version}
                          data-testid={`select-version-${index}`}
                        >
                          {version}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={values.version}>
                        {values.version}
                      </MenuItem>
                    )}
                  </Select>
                )}
              </FormControl>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
