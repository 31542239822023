import * as Yup from "yup";

export interface IndexClientSearchForm {
  client?: string;
}

export const mockIndexClientSearch: IndexClientSearchForm = {
  client: "",
};

export const IndexClientSearchSchema: Yup.SchemaOf<IndexClientSearchForm> =
  Yup.object().shape({
    client: Yup.string(),
  });
