import * as Yup from "yup";
import { Dispatch, SetStateAction } from "react";
import { Client } from "../../../models/client/types";

export type AddClientProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  client?: Client;
};

export interface AddClientForm {
  name: string;
  surName: string;
  email: string;
  phone: string;
  hostComercialId: number;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const addClientSchema: Yup.SchemaOf<AddClientForm> = Yup.object().shape({
  name: Yup.string()
    .min(3, "El nombre debe contener al menos 3 caracteres")
    .required("El nombre es requerido"),
  surName: Yup.string()
    .min(3, "Los apellidos debe contener al menos 3 caracteres")
    .required("Sus apellidos son requeridos"),
  email: Yup.string().email().required("El email es requerido"),
  phone: Yup.string()
    .min(11, "El numero debe contener minimo 11 caracteres")
    .required("El numero telefonico es requerido"),
  hostComercialId: Yup.number().required(),
});
