import { useMediaQuery } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { editPriceForm } from "../../../../views/car/edit/types";
import { useFormik } from "formik";
import { inputPricedProps, modalMap, msgProps } from "./types";
import { editPriceSchema } from "./form";
import { getCar, updateCar } from "../../../../services/car";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getHost } from "../../../../services/host";
import {
  getInsuranceByCarId,
  updateInsurance,
} from "../../../../services/insurance";
import {
  createAddress,
  deleteAddress,
  getAddressesByCarID,
  updateAddress,
} from "../../../../services/address";
import {
  Address,
  AddressStatus,
  AddressType,
} from "../../../../models/address/types";
import {
  createDate,
  deleteDate,
  getDateByCarID,
} from "../../../../services/date";
import { DateStatus } from "../../../../models/date/types";
import { InsuranceStatus } from "../../../../models/insurance/types";
import {
  formatAddressFromDBToGoogle,
  formatAddressGoogleToDB,
  getObjectOfAddresses,
} from "../../../helpers/addressHandler";
import { DomainContext } from "../../../../providers/domainProvider";

export const useEditCarPrice = () => {
  const { carId } = useParams();
  const t = useContext(TranslationContext);
  const { settings } = useContext(DomainContext);
  const queryClient = useQueryClient();
  const [profit, setProfit] = useState<number>(0);
  const [commission, setCommission] = useState<number>(0);
  const [profitInsurance, setProfitInsurance] = useState<number>(0);
  const [commissionInsurance, setCommissionInsurance] = useState<number>(0);
  const [msg, setMsg] = useState<msgProps>({
    text: "",
    type: "error",
  });
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const { data: hostCommercial } = useQuery(["hostC"], () => getHost());
  const [cost, setCost] = useState<boolean>(false);
  const matchesMobile = useMediaQuery("(max-width:1000px)");
  const [isMapOpen, setMapOpen] = useState<modalMap>({
    lat: 0,
    lng: 0,
    completeAddress: "",
    status: false,
  });

  const { data: insurance } = useQuery(
    ["insurance", carId],
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    () => getInsuranceByCarId(parseInt(carId!)),
    {
      enabled: !!carId,
    }
  );

  const { data: car, isSuccess } = useQuery(
    ["car", carId],
    () => getCar(carId),
    {
      enabled: !!carId,
    }
  );

  const { data: addressesData } = useQuery(
    ["addresses", car?.id],
    () => getAddressesByCarID(car?.id),
    {
      enabled: isSuccess,
    }
  );

  // Handle home delivery choose
  const handleHomeDelivery = (event: ChangeEvent<HTMLInputElement>) => {
    formikEditPrice.setFieldValue("homeDelivery", {
      ...formikEditPrice.values.homeDelivery,
      enable: event.target.checked,
      price: event.target.checked
        ? formikEditPrice.values.homeDelivery?.price
        : "",
    });
  };

  // Set prev addresses
  const handlePrincipalAddress = (addressCar: Address[]) => {
    const mainAddress = addressCar.find(
      ({ type }) => type === AddressType.MAIN_ADDRESS
    );
    if (mainAddress) {
      formikEditPrice.setFieldValue(
        "mainAddress",
        formatAddressFromDBToGoogle(mainAddress)
      );

      setMapOpen((prev) => ({
        ...prev,
        lat: parseFloat(mainAddress.latitude || "0"),
        lng: parseFloat(mainAddress.longitude || "0"),
        completeAddress: mainAddress.completeAddress || "",
      }));
    }

    const selectedExtraAddresses = addressCar.filter(({ type }) => type === 1);

    formikEditPrice.setFieldValue(
      "extraAddresses",
      getObjectOfAddresses(selectedExtraAddresses)
    );

    const homeDelivery = addressCar.find(({ type }) => type === 2);

    formikEditPrice.setFieldValue("homeDelivery", {
      price: homeDelivery ? homeDelivery.price : "0",
      enable: homeDelivery ? homeDelivery.status === 1 : true,
    });
    setCost(homeDelivery?.price === undefined || homeDelivery?.price === "");
  };

  // Make request when submit
  const fetchEditPrice = async (values: editPriceForm) => {
    try {
      const datesStorage = await getDateByCarID(parseInt(carId || "0"));

      const deletedDates = (values: editPriceForm) => {
        const arr: number[] = [];

        values.blockDates?.forEach((date) => {
          if (date.id !== 0) {
            arr.push(date.id);
          }
        });

        const datesToDelete = datesStorage.filter(
          (date) => !arr.includes(date.id)
        );

        return datesToDelete;
      };

      deletedDates(values).forEach(async (date) => {
        await deleteDate(date.id.toString());
      });

      const createNewDate = (values: editPriceForm) => {
        const newDates = values.blockDates?.filter((date) => date.id === 0);

        return newDates || [];
      };

      createNewDate(values).forEach(async (date) => {
        await createDate({
          carId: parseInt(carId || "0"),
          dateInitial: dayjs(date.startDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
          dateEnd: dayjs(date.endDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
          status: DateStatus.LOCKED_BY_HOST,
        });
      });

      const resAddresses =
        (await getAddressesByCarID(parseInt(carId || "0"))) ?? [];
      // Check if is a new address or on the db
      const createNewExtraAddresses = (values: editPriceForm) => {
        return Object.values(values.extraAddresses || []).filter(
          (item) => !item.id
        );
      };

      createNewExtraAddresses(values).forEach(async (item) => {
        const newExtraAddress = formatAddressGoogleToDB(
          item.address,
          AddressType.EXTRA_ADDRESS,
          parseInt(carId || "0"),
          item.price
        );

        if (newExtraAddress) {
          await createAddress(newExtraAddress);
        }
      });

      // Delete of extra address
      const deletedAddress = (values: editPriceForm) => {
        const arr: string[] = [];
        Object.entries(values.extraAddresses || {}).forEach(([, value]) => {
          if (value.id) {
            arr.push(value.address.place_id);
          }
        });

        const places = resAddresses.filter(
          (address) =>
            !arr.includes(address.placeId) &&
            address.type === AddressType.EXTRA_ADDRESS
        );

        return places;
      };
      // Delete addresses
      deletedAddress(values).forEach(async (address) => {
        await deleteAddress(address.id.toString());
      });

      // Change address price
      const changePrice = (values: editPriceForm) => {
        const arr: {
          carId: number;
          price: string;
          placeId: string;
          id: number;
        }[] = [];
        Object.entries(values.extraAddresses || {}).forEach(([, value]) => {
          if (!value.id) return;

          const exactAddress = resAddresses.find(
            (item) =>
              item.id === parseInt(value.id || "0") &&
              item.type === AddressType.EXTRA_ADDRESS
          );

          if (exactAddress && value.price !== exactAddress.price) {
            arr.push({
              carId: exactAddress.carId,
              price: value.price,
              placeId: value.address.place_id,
              id: parseInt(value.id || "0"),
            });
          }
        });

        return arr;
      };

      // Change prices of each address
      changePrice(values).forEach(async (address) => {
        await updateAddress(address.id, {
          carId: address.carId,
          price: address.price,
        });
      });

      // Update car information
      await updateCar(parseInt(carId ? carId : "0") || 0, {
        price: values.price,
      });

      // If insurance data was fetched update it
      if (insurance) {
        await updateInsurance({
          id: insurance.id || 0,
          carId: insurance.carId || 0,
          status: insurance.status || InsuranceStatus.DEACTIVATED,
          type: insurance.type,
          charge: values.insuranceCharge?.toString() || "0",
          marketValue: insurance.marketValue || "",
          depositAmount: values.depositAmount?.toString() || "0",
          customerPrice: insurance.customerPrice,
          lastUpdate: insurance.lastUpdate || dayjs().format("YYYY-MM-DD"),
          result: insurance.result,
        });
      }

      const existHomeDelivery = resAddresses?.find(
        (item) => item.type === AddressType.HOME_DELIVERY_ADDRESS
      );
      // Update data of home delivery
      if (existHomeDelivery) {
        await updateAddress(existHomeDelivery.id, {
          carId: car?.id,
          price: values.homeDelivery.price,
          status: values.homeDelivery.enable
            ? AddressStatus.ACTIVE
            : AddressStatus.DEACTIVATED,
        });
      }

      // Send success message
      setMsg({
        text: t("edit.price_disponibility.success.update_car"),
        type: "success",
      });

      // Reset queries to show correct data
      queryClient.invalidateQueries(["dates"]);
      queryClient.invalidateQueries(["addresses"]);
    } catch (error) {
      setMsg({
        text: t("edit.price_disponibility.errors.update_car"),
        type: "error",
      });
    }
    setOpenAlert(true);
  };

  // Initial form
  const formikEditPrice = useFormik<editPriceForm>({
    initialValues: {
      extraAddresses: {},
      mainAddress: {},
      price: "",
      blockDates: [],
      insuranceCharge: "",
      depositAmount: "",
      homeDelivery: { enable: false, price: "" },
    },
    validationSchema: editPriceSchema,
    onSubmit: fetchEditPrice,
  });

  useEffect(() => {
    if (formikEditPrice.values.price && car && car.commission) {
      const commissionValue =
        parseFloat(formikEditPrice.values.price || "0") *
        (parseFloat(settings.payment.applicationFee) / 100);
      setProfit(
        parseFloat(formikEditPrice.values.price || "0") - commissionValue
      );
      setCommission(commissionValue);
    }
  }, [formikEditPrice.values.price, car]);

  useEffect(() => {
    if (formikEditPrice.values.insuranceCharge && car && car.commission) {
      const commissionValue =
        parseFloat(formikEditPrice.values.insuranceCharge || "0") *
        (parseFloat(settings.payment.applicationFee) / 100);
      setProfitInsurance(
        parseFloat(formikEditPrice.values.insuranceCharge || "0") -
          commissionValue
      );
      setCommissionInsurance(commissionValue);
    }
  }, [formikEditPrice.values.insuranceCharge, car]);

  // When car data exist set it to the field
  useEffect(() => {
    formikEditPrice.setFieldValue("price", car?.price);
  }, [car]);

  // When get principal address set it
  useEffect(() => {
    if (addressesData && addressesData.length !== 0) {
      handlePrincipalAddress(addressesData);
    }
  }, [addressesData]);

  // When get insurance data set it
  useEffect(() => {
    if (insurance) {
      formikEditPrice.setFieldValue("insuranceType", insurance.type);
      formikEditPrice.setFieldValue("depositAmount", insurance.depositAmount);
      formikEditPrice.setFieldValue("insuranceCharge", insurance.charge);
    }
  }, [insurance]);

  // Count extra address available (max 4)
  const handleExtraAddressCounter = () => {
    if (
      Object.keys(formikEditPrice.values.extraAddresses || []).length >= 4 ||
      !formikEditPrice.values.extraAddresses
    )
      return;
    formikEditPrice.setFieldValue("extraAddresses", {
      ...formikEditPrice.values.extraAddresses,
      [Object.keys(formikEditPrice.values.extraAddresses).length]: {
        price: "",
      },
    });
  };

  // Set home delivery price
  const homeDeliveryCost = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      formikEditPrice.setFieldValue("homeDelivery", {
        ...formikEditPrice.values.homeDelivery,
        price: "",
      });
    }
    setCost(event.target.checked);
  };

  // Set input price props
  const inputPricedProps: inputPricedProps = {
    variant: "outlined",
    label: t(
      "add.car_price.choose_price_and_availability.price_per_day.placeholder"
    ),
    required: true,
    id: "price_day",
  };

  return {
    t,
    msg,
    car,
    profit,
    insurance,
    profitInsurance,
    commissionInsurance,
    commission,
    openAlert,
    settings,
    setOpenAlert,
    formikEditPrice,
    inputPricedProps,
    hostCommercial,
    matchesMobile,
    handleExtraAddressCounter,
    setMapOpen,
    isMapOpen,
    handleHomeDelivery,
    cost,
    homeDeliveryCost,
  };
};
