import reservationsImages from "../../assets/images/reservations";
import authenticationImages from "../../assets/images/authentication";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { User, UserAccountStatus } from "../../models/user/types";
import { Link } from "react-router-dom";
import { ChangeEmailModal } from "../modals/changeEmail/component";
import { ChangePhoneNumberModal } from "../modals/changePhone/component";
import { ChangePasswordNumberModal } from "../modals/changePassword/component";
import useProfileData from "./useProfileData";
import { Spinner } from "react-bootstrap";
import { EmailVerificationModal } from "../modals/emailVerification/component";
import Toast from "../toast/component";
import { Tooltip } from "@mui/material";

export const ProfileData: FC<User> = ({ ...user }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState<boolean>(false);
  const [isEmailVerificationOpen, setIsEmailVerificationOpen] =
    useState<boolean>(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);
  const [isEmailVerificationCodeResent, setIsEmailVerificationCodeResent] =
    useState<boolean>(false);

  const { t } = useTranslation(["profile", "routes"]);

  const { imageProfile, handleProfileImage, isUploadingImageProfile } =
    useProfileData();

  return (
    <>
      {isEmailVerificationCodeResent && (
        <Toast
          open={isEmailVerificationCodeResent}
          setOpen={setIsEmailVerificationCodeResent}
          type="success"
        >
          {t("profile_data.profile_info.email_sent")}
        </Toast>
      )}
      <div id="profile_data" className="profile-data">
        <div className="welcome-profile">
          <div className="welcome-text">
            <h2 className="nameTitle" id="nombreP">
              {user.name + " " + user.surName}
            </h2>
            <p data-testid="welcome-text">
              {t("profile_data.welcome_profile")}
            </p>
          </div>
          <div className="images">
            {showForm ? (
              <label htmlFor="profileInput">
                {isUploadingImageProfile ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <img
                      className="hover-profile"
                      id="mailImg"
                      src={authenticationImages.change}
                      alt=""
                    />
                    <img
                      className="profile-img"
                      id="mailImg"
                      style={{
                        filter: "brightness(0.8)",
                        backgroundColor: "#ffffff",
                      }}
                      src={
                        imageProfile
                          ? imageProfile.url
                          : authenticationImages.profileDefault
                      }
                      alt=""
                    />
                  </>
                )}
              </label>
            ) : (
              <img
                className="profile-img"
                id="mailImg"
                src={
                  imageProfile
                    ? imageProfile.url
                    : authenticationImages.profileDefault
                }
                alt=""
              />
            )}
          </div>
          <input
            type="file"
            style={{ position: "absolute", visibility: "hidden" }}
            id="profileInput"
            onChange={handleProfileImage}
            className="custom-file-input general-cursor"
            lang="es"
            accept="image/png,image/jpeg,image/jpg,image/heic,image/heif"
          />
        </div>

        {showForm ? (
          <div className="profile-info-form hidden">
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>
                {t("profile_data.profile_info.name")}
                <Tooltip title="Para modificar esta información contacta al equipo de soporte.">
                  <img src={authenticationImages.info} />
                </Tooltip>
              </label>
              <div className="input-text-large">
                <input
                  type="text"
                  className="inputName"
                  placeholder={user.name}
                  disabled
                />
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>
                {t("profile_data.profile_info.surname")}
                <Tooltip title="Para modificar esta información contacta al equipo de soporte.">
                  <img src={authenticationImages.info} />
                </Tooltip>
              </label>
              <div className="input-text-large">
                <input
                  type="text"
                  className="inputSurname"
                  placeholder={user.surName}
                  disabled
                />
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.email.label")}</label>
              <div className="input-text-large">
                <input
                  type="text"
                  className="inputEmail"
                  placeholder={user.email}
                  data-testid="input-email-form"
                  disabled
                />
              </div>
              <a
                className="change-links"
                onClick={() => setIsEmailModalOpen(true)}
              >
                {t("profile_data.profile_info.email.change")}
              </a>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.phone.label")}</label>
              <div className="input-text-large">
                <input
                  type="text"
                  data-testid="input-phone-form"
                  className="inputPhone"
                  placeholder={user.phone}
                  disabled
                />
              </div>
              <a
                className="change-links"
                data-testid="change-phone"
                onClick={() => setIsPhoneModalOpen(true)}
              >
                {t("profile_data.profile_info.phone.change")}
              </a>
            </div>
            <div
              className="profile-generic-info col-sm-12 col-md-12 col-lg-6"
              style={{ marginLeft: 0 }}
            >
              <label>{t("profile_data.profile_info.password.label")}</label>
              <div className="input-text-large">
                <input
                  type="password"
                  className="inputPassword"
                  placeholder="***************"
                  disabled
                />
              </div>
              <a
                className="change-links"
                onClick={() => setIsPasswordModalOpen(true)}
              >
                {t("profile_data.profile_info.password.change")}
              </a>
            </div>
          </div>
        ) : (
          <div className="profile-info">
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.name")}</label>
              <div className="input-text-large">
                <p id="txtnombre" className="name">
                  {user.name}
                </p>
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.surname")}</label>
              <div className="input-text-large">
                <p id="txtapellido" className="surname">
                  {user.surName}
                </p>
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.email.label")}</label>
              <div className="input-text-large">
                <p id="txtcorreo" className="txtEmailReg">
                  {user.email}
                </p>
              </div>
              <div id="Vcorreo" className="verifying hidden">
                {user.status == UserAccountStatus.REGISTER_NO_ACTIVE ||
                user.status == UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL ||
                user.status == UserAccountStatus.INVITED ? (
                  <>
                    <span data-testid="verifying-email-status">
                      <img src={reservationsImages.warning} alt="" />
                      {t("profile_data.profile_info.verify")}
                    </span>
                    <a onClick={() => setIsEmailVerificationOpen(true)}>
                      {t("profile_data.profile_info.email.validate")}
                    </a>
                    <EmailVerificationModal
                      isOpen={isEmailVerificationOpen}
                      setOpen={() => setIsEmailVerificationOpen(false)}
                      user={user}
                      setCodeResent={() =>
                        setIsEmailVerificationCodeResent(true)
                      }
                    />
                  </>
                ) : (
                  <span
                    className="validated"
                    data-testid="validated-email-status"
                  >
                    <img src={authenticationImages.checkGreen} alt="" />
                    {t("profile_data.profile_info.validated")}
                  </span>
                )}
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6">
              <label>{t("profile_data.profile_info.phone.label")}</label>
              <div className="input-text-large">
                <p id="txttel" className="txtTel">
                  {user.phone}
                </p>
              </div>
              <div id="phone" className="verifying hidden">
                {user.status == UserAccountStatus.REGISTER_NO_ACTIVE ||
                user.status == UserAccountStatus.REGISTER_NO_ACTIVE_PHONE ||
                user.status == UserAccountStatus.INVITED ? (
                  <>
                    <span data-testid="verifying-phone-status">
                      <img src={reservationsImages.warning} alt="" />
                      {t("profile_data.profile_info.verify")}
                    </span>
                    <Link to={"/" + t("verify", { ns: "routes" })}>
                      {t("profile_data.profile_info.phone.validate")}
                    </Link>
                  </>
                ) : (
                  <span
                    className="validated"
                    data-testid="validated-phone-status"
                  >
                    <img src={authenticationImages.checkGreen} alt="" />
                    {t("profile_data.profile_info.validated")}
                  </span>
                )}
              </div>
            </div>
            <div className="profile-generic-info col-sm-12 col-md-12">
              <label>{t("profile_data.profile_info.password.label")}</label>
              <div className="input-text-large">
                <p>***************</p>
              </div>
            </div>
          </div>
        )}
        <button
          type="button"
          name="save"
          className={"btn" + (showForm ? " btn-save save" : " btn-edit edit")}
          onClick={() => setShowForm(!showForm)}
          data-testid="edit-save-profile"
        >
          <p>
            {showForm
              ? t("profile_data.profile_info.save_changes")
              : t("profile_data.profile_info.edit_info")}
          </p>
          <img
            src={
              showForm ? authenticationImages.check : authenticationImages.edit
            }
            alt=""
          />
        </button>
      </div>
      <ChangeEmailModal
        isOpen={isEmailModalOpen}
        setOpen={() => setIsEmailModalOpen(false)}
        user={user}
      />
      <ChangePhoneNumberModal
        isOpen={isPhoneModalOpen}
        setOpen={() => setIsPhoneModalOpen(false)}
        user={user}
      />
      <ChangePasswordNumberModal
        isOpen={isPasswordModalOpen}
        setOpen={() => setIsPasswordModalOpen(false)}
      />
    </>
  );
};
