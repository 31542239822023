import { Alert, Box, Button, ListItem, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./StatusAlert.scss";
import { useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import carImagesIndex from "../../../../assets/images/car/index";
import {
  DocStatus,
  User,
  UserAccountStatus,
} from "../../../../models/user/types";
import { KycStatus } from "../../../../views/profile/types";
import {
  ADDRESS_ACCOUNT_ICON_STATUS,
  OFICIAL_DOCUMENTS_ICON_STATUS,
  PHONE_EMAIL_ICON_STATUS,
} from "./types";

const StatusAlert = ({
  type,
  user,
}: {
  type: "requiresAtention" | "blocked";
  user: User;
}) => {
  const { t } = useTranslation(["navbar", "routes"]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const color =
    type === "requiresAtention" ? "#3CB2B9" : "var(--Semantic-Colors-Error)";

  const DOCUMENTS_STATUS_COLOR = useRef({
    0: "var(--Semantic-Colors-Warning-Yellow)",
    1: "var(--Semantic-Colors-Success)",
  });

  const OFICIAL_DOCUMENTS_STATUS_COLOR = useRef({
    0: "var(--Semantic-Colors-Warning-Yellow)",
    1: "var(--Semantic-Colors-Warning-Yellow)",
    2: "var(--Semantic-Colors-Success)",
    3: "var(--Semantic-Colors-Error)",
    4: "var(--Semantic-Colors-Warning-Yellow)",
    5: "var(--Semantic-Colors-Error)",
  });

  const ADDRESS_ACCOUNT_STATUS_COLOR = useRef({
    0: "var(--Semantic-Colors-Warning-Yellow)",
    1: "var(--Semantic-Colors-Warning-Yellow)",
    2: "var(--Semantic-Colors-Success)",
    3: "var(--Semantic-Colors-Error)",
  });

  const PHONE_EMAIL_STATUS = useRef({
    0: t("status_alert.documents.status.not_approved"),
    1: t("status_alert.documents.status.approved"),
  });

  const OFICIAL_DOCUMENTS_STATUS = useRef({
    0: t("status_alert.documents.status.not_uploaded"),
    1: t("status_alert.documents.status.in_review"),
    2: t("status_alert.documents.status.approved"),
    3: t("status_alert.documents.status.refused"),
  });

  const ADDRESS_ACCOUNT_STATUS = useRef({
    0: t("status_alert.documents.status.not_uploaded"),
    1: t("status_alert.documents.status.in_review"),
    2: t("status_alert.documents.status.approved"),
    3: t("status_alert.documents.status.refused"),
  });

  const message =
    type === "requiresAtention" ? (
      <p>
        {t("status_alert.complete_profile")}
        <Link to={"/" + t("profile", { ns: "routes" })}>
          {t("status_alert.go_to_profile")}
        </Link>
      </p>
    ) : (
      t("status_alert.blocked_account")
    );

  const phoneActive =
    user.status === UserAccountStatus.REGISTER_NO_ACTIVE ||
    user.status === UserAccountStatus.REGISTER_NO_ACTIVE_PHONE ||
    user.status == UserAccountStatus.INVITED
      ? 0
      : 1;

  const emailActive =
    user.status === UserAccountStatus.REGISTER_NO_ACTIVE ||
    user.status === UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL ||
    user.status == UserAccountStatus.INVITED
      ? 0
      : 1;

  const oficialDocumentsActive =
    user.docOfficialDocuments === KycStatus.NOT_STARTED
      ? 0
      : user.docOfficialDocuments === KycStatus.PENDING
      ? 1
      : user.docOfficialDocuments === KycStatus.SUCCESS
      ? 2
      : 3;

  return (
    <Alert
      variant="filled"
      icon={false}
      className="status-alert"
      sx={{ backgroundColor: color }}
    >
      <div className="status-alert__message">
        {message}
        {type !== "blocked" && (
          <>
            <Button
              data-testid="user-documents-status"
              className="user-documents-status"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <DocumentUserStatusListItem user={user} />
            </Button>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{ sx: { borderRadius: "16px", width: "340px" } }}
            >
              <Box
                sx={{
                  background: "var(--Utility-Colors-Secondary-Background)",
                  border: "none",
                  borderRadius: "16px",
                }}
              >
                <ListItem
                  onClick={() => setAnchorEl(null)}
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    cursor: "pointer",
                    paddingRight: 0,
                    color: DOCUMENTS_STATUS_COLOR.current[phoneActive],
                  }}
                >
                  {PHONE_EMAIL_ICON_STATUS[phoneActive]}
                  {t("status_alert.documents.phone")}:{" "}
                  {PHONE_EMAIL_STATUS.current[phoneActive]}
                </ListItem>
                <ListItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color:
                      OFICIAL_DOCUMENTS_STATUS_COLOR.current[
                        oficialDocumentsActive
                      ],
                  }}
                >
                  {OFICIAL_DOCUMENTS_ICON_STATUS[oficialDocumentsActive]}
                  {t("status_alert.documents.oficial_documents")}:{" "}
                  {OFICIAL_DOCUMENTS_STATUS.current[oficialDocumentsActive]}
                </ListItem>
                <ListItem
                  data-testid="car-status"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color:
                      ADDRESS_ACCOUNT_STATUS_COLOR.current[
                        user?.docBankStatement || 0
                      ],
                  }}
                >
                  {ADDRESS_ACCOUNT_ICON_STATUS[user?.docBankStatement || 0]}
                  {t("status_alert.documents.account_status")}:{" "}
                  {ADDRESS_ACCOUNT_STATUS.current[user?.docBankStatement || 0]}
                </ListItem>
                <ListItem
                  data-testid="car-status"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    gap: "5px",
                    color: "var(--Utility-Colors-Primary-Text)",
                  }}
                >
                  <hr style={{ width: "30%" }} />
                  {t("status_alert.recommended")}
                  <hr style={{ width: "30%" }} />
                </ListItem>

                <ListItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color:
                      ADDRESS_ACCOUNT_STATUS_COLOR.current[
                        user?.docProofOfAddress || 0
                      ],
                  }}
                >
                  {ADDRESS_ACCOUNT_ICON_STATUS[user?.docProofOfAddress || 0]}
                  {t("status_alert.documents.proof_of_address")}:{" "}
                  {ADDRESS_ACCOUNT_STATUS.current[user?.docProofOfAddress || 0]}
                </ListItem>
                <ListItem
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: DOCUMENTS_STATUS_COLOR.current[emailActive],
                  }}
                >
                  {" "}
                  {PHONE_EMAIL_ICON_STATUS[emailActive]}
                  {t("status_alert.documents.email")}:{" "}
                  {PHONE_EMAIL_STATUS.current[emailActive]}
                </ListItem>
              </Box>
            </Popover>
          </>
        )}
      </div>
    </Alert>
  );
};

const DocumentUserStatusListItem = ({ user }: { user: User }) => {
  const { t } = useTranslation(["navbar"]);

  return (
    <div className="user-button-status">
      <div>
        <img
          alt="documents-warning"
          src={carImagesIndex.not_uploaded}
          style={{ margin: "0 6px 2px 0", height: "unset", width: "unset" }}
        />
        {`${
          Object.values({
            phone:
              user.status === UserAccountStatus.REGISTER_NO_ACTIVE ||
              user.status === UserAccountStatus.REGISTER_NO_ACTIVE_PHONE ||
              user.status === UserAccountStatus.INVITED,
            documents: user.docOfficialDocuments !== KycStatus.SUCCESS,
            address: user.docProofOfAddress !== DocStatus.VALIDATED,
            account: user.docBankStatement !== DocStatus.VALIDATED,
            email:
              user.status === UserAccountStatus.REGISTER_NO_ACTIVE ||
              user.status === UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL ||
              user.status === UserAccountStatus.INVITED,
          }).filter((item) => item).length
        } ${t("status_alert.problems_in_document")}
          `}
      </div>
    </div>
  );
};

export default StatusAlert;
