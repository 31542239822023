import * as Yup from "yup";

export interface ClientTripForm {
  order?: string;
}

export const mockClientTrip: ClientTripForm = {
  order: "",
};

export const ClientTripSchema: Yup.SchemaOf<ClientTripForm> =
  Yup.object().shape({
    order: Yup.string(),
  });
