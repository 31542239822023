import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import navbarImages from "../../../assets/images/navbar";
import { useFormik } from "formik";
import { updateCodeEmail, updateUserStatus } from "../../../services/user";
import "./styles.scss";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { User, UserAccountStatus } from "../../../models/user/types";
import { useTranslation } from "react-i18next";

interface modalValidateEmailForm {
  code: string;
  typeCode: string;
}

interface ModalValidateEmailProps {
  user: User;
  isOpen: boolean;
  setOpen: () => void;
  setCodeResent: () => void;
}

const modalValidateEmailSchema: Yup.SchemaOf<modalValidateEmailForm> =
  Yup.object().shape({
    code: Yup.string().required("El codigo es requerido"),
    typeCode: Yup.string()
      .oneOf([Yup.ref("code")], "El codigo debe de coincidir")
      .required("El codigo es requerido"),
  });

export const EmailVerificationModal: FC<ModalValidateEmailProps> = ({
  user,
  isOpen,
  setOpen,
  setCodeResent,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["modals"]);

  const fetchValidateEmail = async (value: modalValidateEmailForm) => {
    if (value.code != value.typeCode) return;

    const response = await updateUserStatus(
      SetAccountStatus(user.status || UserAccountStatus.REGISTER_NO_ACTIVE)
    );
    if (response?.status != 200) return;

    setOpen();
    queryClient.invalidateQueries(["profile"]);
  };

  const SetAccountStatus = (status: UserAccountStatus) => {
    switch (status) {
      case UserAccountStatus.INVITED:
      case UserAccountStatus.REGISTER_NO_ACTIVE:
        return UserAccountStatus.REGISTER_NO_ACTIVE_PHONE;
      case UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL:
        return UserAccountStatus.ACTIVE;
      default:
        return UserAccountStatus.REGISTER_NO_ACTIVE;
    }
  };

  useEffect(() => {
    if (user && user.codeMail) {
      formikValidateEmailModal.setFieldValue(
        "code",
        user.codeMail.toLowerCase()
      );
    }
  }, [user]);

  const formikValidateEmailModal = useFormik<modalValidateEmailForm>({
    initialValues: {
      code: "",
      typeCode: "",
    },
    validationSchema: modalValidateEmailSchema,
    onSubmit: fetchValidateEmail,
  });

  const styles = {
    inputEmailCode: {
      borderColor:
        formikValidateEmailModal.submitCount != 0 &&
        formikValidateEmailModal.errors
          ? "red"
          : "#828282",
    },
  };

  return (
    <Modal show={isOpen} className="email-verification-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.crossBlack} alt="" />
        </button>
        <h2 data-testid="email-verification-title">
          {t("email_verification.email_validation")}
        </h2>
        <h3>
          {t("email_verification.type_code")}{" "}
          <b id="txtEmailReg">{user.email}</b>.
        </h3>
      </div>
      <div className="modal-body">
        <div className="email-verification-content">
          <div className="form">
            <p className="dropdown-item">
              {t("email_verification.unique_code")}
            </p>
            <input
              type="text"
              id="txtActCode"
              className="bill-input"
              style={styles.inputEmailCode}
              placeholder="XXXXXX"
              onChange={(e) =>
                formikValidateEmailModal.setFieldValue(
                  "typeCode",
                  e.target.value?.toLowerCase()
                )
              }
              onBlur={formikValidateEmailModal.handleBlur("typeCode")}
            />
            <span className="email-code-error"></span>
          </div>
          <div className="resend-email">
            <p>{t("email_verification.code_missing")}</p>
            <a
              type="button"
              id="Re-enviar"
              onClick={() => {
                updateCodeEmail();
                setOpen();
                setCodeResent();
              }}
            >
              {t("email_verification.resend_code")}
            </a>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex">
          <button
            type="button"
            id="bttcode"
            onClick={() => formikValidateEmailModal.handleSubmit()}
            className="btn btn-orange"
          >
            {t("email_verification.ready")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
