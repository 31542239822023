import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    purpleA: PaletteColorOptions;
    balety: PaletteColorOptions;
    aqua: PaletteColorOptions;
  }
  interface PaletteOptions {
    purpleA: PaletteColorOptions;
    balety: PaletteColorOptions;
    aqua: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    purpleA: true;
    balety: true;
  }
}

declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    balety: true;
    aqua: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    balety: true;
    aqua: true;
  }
}

declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    balety: true;
    aqua: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    balety: true;
    aqua: true;
  }
}

const { palette } = createTheme();
export const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    purpleA: palette.augmentColor({ color: purple }),
    balety: palette.augmentColor({ color: { main: "#b72446" } }),
    aqua: palette.augmentColor({ color: { main: "#3CB2B9" } }),
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          transform: "translate(-97%, -210%);",
          '&[data-index="0"]': {
            transform: "translate(0%, -210%);",
          },
        },
      },
    },
  },
});
