import * as Yup from "yup";
import {
  CarStatusIndex,
  CarType,
  Transmission,
} from "../../../models/car/types";

export interface IndexCarSearchForm {
  carName?: string;
  status: CarStatusIndex[];
  type: CarType[];
  transmission: Transmission[];
}
export const mockIndexCarSearch: IndexCarSearchForm = {
  carName: "",
  status: [0, 1, 2, 3, 4],
  transmission: [0, 1],
  type: [0, 1, 2, 3, 4, 5, 6],
};

export const IndexCarSearchSchema: Yup.SchemaOf<IndexCarSearchForm> =
  Yup.object().shape({
    carName: Yup.string(),
    status: Yup.array()
      .of(Yup.mixed().oneOf(Object.values(CarStatusIndex)))
      .required(),
    type: Yup.array()
      .of(Yup.mixed().oneOf(Object.values(CarType)))
      .required(),
    transmission: Yup.array()
      .of(Yup.mixed().oneOf(Object.values(Transmission)))
      .required(),
  });
