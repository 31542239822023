import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import "./ClientOptions.scss";
import { Modal } from "@mui/material";
import { useQueryClient } from "react-query";
import { editClientByHost } from "../../../services/client";
import { UserStatus } from "../../../models/user/types";

type ClientOptionsProps = {
  id: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type: "delete" | "block";
};

export const ClientOptionsModal = ({
  id,
  open,
  setOpen,
  type,
}: ClientOptionsProps) => {
  const { t } = useTranslation(["client"]);
  const queryClient = useQueryClient();

  const blockClient = async () => {
    const response = await editClientByHost({
      id,
      isBlocked: UserStatus.BLOCKED,
    });

    if (!response || response.status != 200) return;

    queryClient.resetQueries(["clients"]);
  };

  const deleteClient = async () => {
    const response = await editClientByHost({
      id,
      isBlocked: UserStatus.PROCESS_DELETE,
    });

    if (!response || response.status != 200) return;

    queryClient.resetQueries(["clients"]);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="client-options-modal"
    >
      <div className="client-options-container">
        <div className="client-options-header">
          <h4 className="client-options-header__title">
            {type === "block"
              ? t("modals.block_client.title")
              : t("modals.delete_client.title")}
          </h4>
          <div className="btn-close-container">
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
        </div>
        <div className="client-options-body">
          <p className="client-options-body__info">
            {type === "block"
              ? t("modals.block_client.block_info")
              : t("modals.delete_client.delete_info")}
          </p>
        </div>
        <div className="client-options-footer">
          <button className="btn-cancel" onClick={() => setOpen(false)}>
            {t("modals.block_client.cancel")}
          </button>
          {type === "block" ? (
            <button className="btn-action" onClick={blockClient}>
              {t("modals.block_client.block_user")}
            </button>
          ) : (
            <button className="btn-action" onClick={deleteClient}>
              {t("modals.delete_client.delete_user")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
